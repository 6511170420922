import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Block, Text } from 'suomifi-ui-components'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { FrontAsiakirjaLiite } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const YhteenvetoGroupBlock = styled(Block)`
  display: flex;
  flex-direction: column;
`

export type YhteenvetoGroupProps = {
  heading: string
  rows: (string | undefined)[]
  attachments?: FrontAsiakirjaLiite[]
}

export const YhteenvetoGroups: React.FC<{
  groups: YhteenvetoGroupProps[]
  requireRowsOrAttachments?: boolean
}> = observer(({ groups, requireRowsOrAttachments = true }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { asiaType } = getTili()

  return (
    <Block mb={verticalMarginToken}>
      <YhteenvetoGroupBlock>
        {!groups.length && (
          <Text smallScreen>
            {asiaType === 'OMAISUUSLUETTELO'
              ? t('eiIlmoitettujaTietojaTehtavasiAlkamispaivana')
              : t('eiIlmoitettujaTietojaTallaTilikaudella')}
          </Text>
        )}
        {groups.map((group, groupsIndex) => {
          const nonEmptyRows = group.rows.filter((row) => row)
          return (
            <YhteenvetoGroupBlock mb={verticalMarginToken} key={groupsIndex}>
              {group.heading && (
                <Text
                  smallScreen
                  variant={'bold'}
                  mb="xxs"
                  mt={groupsIndex === 0 ? '0' : verticalMarginToken}
                >
                  {group.heading}
                </Text>
              )}
              {!nonEmptyRows.length &&
                !group.attachments?.length &&
                requireRowsOrAttachments && (
                  <Text smallScreen>
                    {asiaType === 'OMAISUUSLUETTELO'
                      ? t('eiIlmoitettujaTietojaTehtavasiAlkamispaivana')
                      : t('eiIlmoitettujaTietojaTallaTilikaudella')}
                  </Text>
                )}
              {nonEmptyRows.map((row, rowIndex) => {
                return (
                  <Text smallScreen key={rowIndex}>
                    {row}
                  </Text>
                )
              })}
              {group.attachments && (
                <AttachmentList attachments={group.attachments} />
              )}
            </YhteenvetoGroupBlock>
          )
        })}
      </YhteenvetoGroupBlock>
    </Block>
  )
})

export default YhteenvetoGroups
