import { Tilirivit } from 'tilintarkastus-common/src/vtj/types/asiointi-account-rows.type'
import { MimeType } from 'common/src/vtj/MimeType.enum'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import {
  AsiointiHabitationPeriodType,
  AsiointiHabitationType,
} from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { TiliAsiakirjaTypeId } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { GuardianshipEndReasonId } from 'tilintarkastus-common/src/vtj/tili-account-enums'

export interface AsiointiAccountDataInput {
  seurantaAsiaVaatimusId: number
  isMuutMerkittavatToimet: boolean
  muutMerkittavatToimet?: string
  isAnnettuKayttovaroja?: boolean
  kayttovaraKaytanto?: string
  asuinpaikat: Asuinpaikka[]
  saannollisetTulot: SaannollinenTulo[]
  elaketulotElinkustannuksiin?: number
  verotustiedot: Verotustieto[]
  palkkio: Palkkio
  isHallintaoikeuksia: boolean
  mitenHallintaoikeuksistaOnSovittu?: string
  hallintaoikeusSopimukset: AsiakirjaLiite[]
  sopimukset: Sopimus[]
  vakuutukset: Vakuutus[]
  tilirivit?: Tilirivit
  edunvalvojanValtakirja?: AsiakirjaLiite[]
  toimintakyky?: string
  isAsuinpaikkaTuntematon?: boolean
  kaytossaOlevaOmaisuus?: KayttoOmaisuus[]
  hasKaytossaOlevaOmaisuus?: boolean
  hasHoitosuunnitelma?: boolean
  hoitosuunnitelmat?: AsiakirjaLiite[]
  asuuHuoltajanKanssa?: boolean
  huoltajienNimet?: string
  guardianshipBeginDate: string
  guardianshipEndDate?: string
  guardianshipEndReason?: GuardianshipEndReasonId
  accountingPeriodBeginDate: string
  accountingPeriodEndDate: string
}

export type KayttoOmaisuus = { value: string }
export interface AsiointiPropertyInventoryDataInput {
  seurantaAsiaVaatimusId: number
  muutMerkittavatToimet?: string
  kayttovaraKaytanto?: string
  verotustiedot: Verotustieto[]
  saannollisetTulot: SaannollinenTulo[]
  sopimukset: Sopimus[]
  vakuutukset: Vakuutus[]
  tilirivit?: Pick<Tilirivit, 'varat' | 'velat'>
  edunvalvojanValtakirja?: AsiakirjaLiite[]
  toimintakyky?: string
  paamiehenOmaisuus?: PaamiehenOmaisuus
  asuminen?: Asuminen
  elaketulotElinkustannuksiin?: number
  testamentti?: Testamentti
  hallintaoikeudet?: Hallintaoikeudet
  guardianshipBeginDate: string
  accountingPeriodBeginDate?: string
  accountingPeriodEndDate?: string
}

export interface Hallintaoikeudet {
  mitenSovittu: string
  sopimukset: AsiakirjaLiite[]
}

export interface Testamentti {
  laadittu: KyllaEiTuntematon
  rajoittaviaEhtoja?: KyllaEiTuntematon
}

export enum KyllaEiTuntematon {
  KYLLA = 'KYLLA',
  EI = 'EI',
  TUNTEMATON = 'TUNTEMATON',
}

export interface Asuminen {
  asuinpaikat?: Asuinpaikka[]
  huoltajalla?: string
  hoitoPalveluSuunnitelma?: AsiakirjaLiite[]
}

export interface PaamiehenOmaisuus {
  pankkitilit: Pankkitili[]
  muutVarat?: string
  omaisuusOmassaKaytossa?: string[]
}

export interface Pankkitili {
  iban: string
  kayttovaratili: boolean
  pankinNimi: string
  kayttooikeudet?: string
  sopimukset: AsiakirjaLiite[]
}

export interface Asuinpaikka {
  streetAddress?: string
  streetAddressExtra?: string
  postalCode?: string
  postOffice?: string
  countryCode: string
  beginDate?: string
  endDate?: string
  habitationType: AsiointiHabitationType
  habitationPeriodType: AsiointiHabitationPeriodType
}

export interface SaannollinenTulo {
  tyyppi: AsiointiLedgerAccountIncomeType
  maksaja: string
  nettosumma: number
  beginDate?: string
  endDate?: string
  asiakirjat?: AsiakirjaLiite[]
  mihinPankkitililleMaksetaan?: AlaikaisenTilinTyyppi
}

export type AsiakirjaLiite = {
  sourceFileId: string
  filename: string
  sizeBytes: number
  mimeType: MimeType
  asiakirjatyyppi: TiliAsiakirjaTypeId
}

export type Verotustieto = AsiakirjaLiite

export type Palkkio = {
  veloitatkoPalkkiota: boolean
  palkkioMaara?: number
  veloitatkoKulukorvauksia: boolean
  kulukorvausMaara?: number
  kululaskelma: AsiakirjaLiite[]
}

export interface Sopimus {
  sopimustyyppi: string
  toimittaja: string
  beginDate?: string
  endDate?: string
  voimassaoloaika?: Voimassaoloaika
  asiakirjat?: AsiakirjaLiite[]
}

export interface Vakuutus {
  vakuutustyyppi: string
  toimittaja: string
  beginDate?: string
  endDate?: string
  voimassaoloaika?: Voimassaoloaika
  asiakirjat?: AsiakirjaLiite[]
}

export enum Voimassaoloaika {
  MAARA_AIKAINEN = 'MAARA_AIKAINEN',
  TOISTAISEKSI_VOIMASSA_OLEVA = 'TOISTAISEKSI_VOIMASSA_OLEVA',
}

export enum AlaikaisenTilinTyyppi {
  HUOLTAJAN_HALLINNOIMA = 'HUOLTAJAN_HALLINNOIMA',
  ALAIKAISEN_OMA = 'ALAIKAISEN_OMA',
  HUOLTAJAN_OMA = 'HUOLTAJAN_OMA',
}
