import {
  getTilintarkastusStore,
  runTilintarkastusStoreAction,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  Paatostili,
  FrontPalkkio,
  Omaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiAccountDataInput } from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'

export const getPaatostili = (): Paatostili => {
  const lomake = getTilintarkastusStore().lomake
  assertIsPaatostili(lomake.tili)
  return lomake.tili as Paatostili
}

export const getTili = (): Paatostili | Omaisuusluettelo => {
  const lomake = getTilintarkastusStore().lomake
  return lomake.tili
}

export const isPaatostili = (
  tili: Paatostili | Omaisuusluettelo
): tili is Paatostili => {
  return tili?.asiaType === 'PAATOSTILI'
}

export type UpdateAccountValues = Partial<
  Pick<
    AsiointiAccountDataInput,
    | 'isAnnettuKayttovaroja'
    | 'kayttovaraKaytanto'
    | 'isMuutMerkittavatToimet'
    | 'muutMerkittavatToimet'
    | 'asuinpaikat'
    | 'isHallintaoikeuksia'
    | 'mitenHallintaoikeuksistaOnSovittu'
    | 'saannollisetTulot'
    | 'sopimukset'
    | 'vakuutukset'
    | 'tilirivit'
  >
>

const assertIsPaatostili = (tili: Paatostili | Omaisuusluettelo) => {
  if (!isPaatostili(tili)) {
    throw new Error('Not an Päätöstili')
  }
}

export const updateAccountValues = (values: UpdateAccountValues): void =>
  runTilintarkastusStoreAction(({ lomake }) => {
    assertIsPaatostili(lomake.tili)
    Object.assign(lomake.tili, values)
  })

export const updatePalkkioValues = (
  values: Partial<Omit<FrontPalkkio, 'kululaskelma'>>
): void =>
  runTilintarkastusStoreAction(({ lomake }) => {
    assertIsPaatostili(lomake.tili)
    Object.assign((lomake.tili as Paatostili).palkkio, values)
  })

export const getTilirivit = ():
  | Omaisuusluettelo['tilirivit']
  | Paatostili['tilirivit'] =>
  runTilintarkastusStoreAction(({ lomake }) => {
    return lomake.tili.tilirivit
  })

export const getPaatostiliTilirivit = (): Paatostili['tilirivit'] =>
  runTilintarkastusStoreAction(({ lomake }) => {
    assertIsPaatostili(lomake.tili)
    return (lomake.tili as Paatostili).tilirivit
  })
