/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostiliTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontVuokraTaiVastike } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiMenoTyyppiBySubcategory } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import _ from 'lodash'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'

const VuokratVastikkeetForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tilirivit = getPaatostiliTilirivit()

  const mkNewElement = (): FrontVuokraTaiVastike =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      nimi: '',
      tunnus: '',
      tyyppi: undefined,
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('vuokratVastikkeet-form')}>
      <Heading variant="h3">{t('vuokratVastikkeetHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaVuokratVastikkeet')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={VuokraTaiVastikeListItem}
        state={tilirivit.menot.vuokratVastikkeet}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.MENOT}
        dataTestIdPrefix={mkTulotMenotTestId('vuokratVastikkeet-form')}
        ModalContentComponent={VuokraTaiVastikeModal}
        newEntry={mkNewElement}
        translationPrefix="vuokratVastikkeet"
      />
    </section>
  )
}

const VuokraTaiVastikeModal: FC<FormModalProps<FrontVuokraTaiVastike>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const vuokraTaiVastiketyypit = useMemo(
      () =>
        _.sortBy(
          Object.values(
            AsiointiMenoTyyppiBySubcategory(
              AsiointiLedgerAccountSubCategory.VUOKRAT_JA_VASTIKKEET
            )
          ).map((value) => ({
            name: t(`expenseType_${value}`),
            value,
          })),
          'name'
        ),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('vuokranTaiVastikkeensenTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={vuokraTaiVastiketyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountExpenseType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        {entry.tyyppi === AsiointiLedgerAccountExpenseType.YHTIOVASTIKE && (
          <>
            <FormTextInput
              data-test-id={createDataTestId('modal-nimi')}
              labelText={t('kiinteistonTaiYhtionNimi')}
              value={entry.nimi}
              required
              validate={commonXssValidator(t)}
              updateValue={(value) => setEntry({ ...entry, nimi: value })}
            />

            <FormTextInput
              data-test-id={createDataTestId('modal-tunnus')}
              labelText={t('kiinteistonTunnusTaiOsakkeidenNumerot')}
              value={entry.tunnus}
              required
              validate={commonXssValidator(t)}
              updateValue={(value) => setEntry({ ...entry, tunnus: value })}
            />
          </>
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-menotYhteensa')}
          labelText={t('menotTilikaudenAikanaYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  })

const VuokraTaiVastikeListItem: FC<FormElementProps<FrontVuokraTaiVastike>> =
  observer(({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const yhteensaEur = `${formatNumberWithComma(entry.yhteensa!)} €`

    return (
      <FormListInputExpander
        heading={t(`expenseType_${entry.tyyppi}`)}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        {entry.tyyppi === AsiointiLedgerAccountExpenseType.YHTIOVASTIKE && (
          <>
            <Heading variant="h5">{t('kiinteistonTaiYhtionNimi')}</Heading>
            <Text>{entry.nimi}</Text>
            <Block mt={verticalMarginToken} />

            <Heading variant="h5">
              {t('kiinteistonTunnusTaiOsakkeidenNumerot')}
            </Heading>
            <Text>{entry.tunnus}</Text>
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  })

export default observer(VuokratVastikkeetForm)
