import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

export const getOmaisuusluettelo = (): Omaisuusluettelo => {
  const lomake = getTilintarkastusStore().lomake
  assertIsOmaisuusluettelo(lomake.tili)
  return lomake.tili as Omaisuusluettelo
}

export const isOmaisuusluettelo = (
  tili: Paatostili | Omaisuusluettelo
): tili is Omaisuusluettelo => {
  return tili.asiaType === 'OMAISUUSLUETTELO'
}

const assertIsOmaisuusluettelo = (tili: Omaisuusluettelo | Paatostili) => {
  if (!isOmaisuusluettelo(tili)) {
    throw new Error('Not a omaisuusluettelo')
  }
}
