import React, { PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import {
  Block,
  Button,
  Expander,
  ExpanderContent,
  ExpanderTitleButton,
  ExternalLink,
  Heading,
  IconLaptopContent,
  IconLogin,
  IconMoney,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import QueryParam from 'common/src/vtj/QueryParam.enum'
import { mkLandingPageTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import { useGetLoginUri } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/login-redirect'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getLandingTilinpaatosHelpUrl,
  omaisuusluetteloOhjeUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import FeatureNotification from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/FeatureNotification'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import styled from 'styled-components'
import { HelpSection } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/HelpSection'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'

const LandingPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const location = useLocation()
  const isAuthenticationCancelled = location.search.includes(
    `${QueryParam.SUOMIFI_AUTH_CANCELED}=true`
  )
  const isAuthenticationFailed = location.search.includes(
    `${QueryParam.SUOMIFI_AUTH_FAILURE}=true`
  )

  return (
    <>
      <HeroBackground>
        <CenteredWidthLimitedDiv paddingX paddingY="xxl">
          <Heading variant="h1hero" color="whiteBase">
            {t('edunvalvontaHeader')}
          </Heading>
          <Block mt="xl" />
          <HeroText>{t('landingHeroDescription')}</HeroText>
        </CenteredWidthLimitedDiv>
      </HeroBackground>

      <CenteredWidthLimitedDiv paddingX paddingY>
        {isAuthenticationCancelled && (
          <>
            <InlineAlert status="warning">
              {t('authenticationCanceled')}
            </InlineAlert>
            <Block mb="s" />
          </>
        )}
        {isAuthenticationFailed && (
          <>
            <InlineAlert
              status="error"
              labelText={t('authenticationFailure_1')}
            >
              <Paragraph>{t('authenticationFailure_1')}</Paragraph>
              <Paragraph>{t('authenticationFailure_2')}</Paragraph>
              <Paragraph>{t('authenticationFailure_3')}</Paragraph>
            </InlineAlert>
            <Block mb="s" />
          </>
        )}

        <Heading variant="h1" as={'h2'}>
          {t('landingMitaVoitTehdaHeader')}
        </Heading>
        <Block mt="m" />
        <FeatureNotification />
        <Block mt="xs" />
        <TiliGuestSection />
        <Block mt="xs" />
        <LupaHakemusGuestSection />
        <Block mt="xs" />
        <HelpSection />
      </CenteredWidthLimitedDiv>
    </>
  )
})

const LupaHakemusGuestSection: React.FC = observer(() => {
  const [t] = useTranslation()
  const userStore = useAsiointiUserStore()
  const loginUri = useGetLoginUri()
  const isTablet = useDeviceContext().tablet

  return (
    <LupaNostokortti ariaLiveMode="off" smallScreen={!isTablet}>
      <LaptopIcon />
      <Heading variant="h3">{t('overviewLupaInfoHeader')}</Heading>
      <Block mt="s" />
      <Text>{t('landingLuvatKuvaus1')}</Text>
      <Block mt="s" />
      <Text>{t('landingLuvatKuvaus2')}</Text>
      <Block mt="m" />

      <Expander>
        <ExpanderTitleButton>
          {t('landingLuvatExpanderKukaHeader')}
        </ExpanderTitleButton>
        <ExpanderContent>
          <Text>{t('landingLuvatExpanderKukaItem1')}</Text>
          <Block mt="s" />
          <Text>{t('landingLuvatExpanderKukaItem2')}</Text>
          <Block mt="s" />
          <ExternalLink
            href={mkLandingPageTestId(userStore.lang)}
            variant="accent"
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('landingLuvatExternalInstructionsLinkTitle')}
          </ExternalLink>
          <Block mt="s" />
        </ExpanderContent>
      </Expander>
      <Block mt="m" />
      <Button
        data-test-id={mkLandingPageTestId('loginBottom')}
        onClick={() => (window.location.href = loginUri)}
        icon={<IconLogin />}
        fullWidth={false}
        role="link"
      >
        {t('loginButtonLabel')}
      </Button>
    </LupaNostokortti>
  )
})

const TiliGuestSection = observer(() => {
  const [t] = useTranslation()
  const userStore = useAsiointiUserStore()
  const isTablet = useDeviceContext().tablet
  const loginUri = useGetLoginUri()

  const tiedot = [
    'tarvittavatTiedot_sopimukset',
    'tarvittavatTiedot_veroilmoitus',
    'tarvittavatTiedot_talletukset_sijoitukset',
    'tarvittavatTiedot_lainat_takaukset',
    'tarvittavatTiedot_perukirjat',
    'tarvittavatTiedot_valtakirja',
  ]

  return (
    <TiliNostokortti
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
      data-test-id={mkLandingPageTestId('tili-korttikehys')}
    >
      <MoneyIcon />
      <Block mt="m" />
      <Heading variant="h3" as="h2">
        {t('tilinTekeminen')}
      </Heading>
      <Block mt="m" />

      {isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.OMAISUUSLUETTELO) ? (
        <Text>{t('evTiliKuvausPaatostiliTaiOmaisuusluettelo')}</Text>
      ) : (
        <Text>{t('evTiliKuvaus1')}</Text>
      )}
      <Block mt="m" />
      <Text>{t('evTiliKuvaus2')}</Text>

      <Block mt="s" />
      <Expander data-test-id={mkLandingPageTestId('omaisuusluettelo-expander')}>
        <ExpanderTitleButton>
          {t('mitaTarvitsenPaatostilinTekoon')}
        </ExpanderTitleButton>
        <ExpanderContent>
          <Block mt="s" />
          <Text>{t('mitaTarvitsenPaatostilinTekoon-kuvaus')}</Text>
          <Block mt="s" />
          <Text variant="bold">{t('paatostilinTarvittavatTiedotHead')}</Text>
          <Block mt="s" />
          <DiscList>
            {tiedot.map((key) => (
              <li key={key}>
                <Text>{t(key)}</Text>
              </li>
            ))}
          </DiscList>
          <Block mt="s" />
          <Text>{t('paatostilinTarvittavatTiedotFooter')}</Text>
          <Block mt="m" />
          <ExternalLink
            href={getLandingTilinpaatosHelpUrl(userStore.lang)}
            variant="accent"
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('landingTiliExternalInstructionsLinkTitle')}
          </ExternalLink>
        </ExpanderContent>
      </Expander>
      <Block mt="m" />
      <Expander>
        <ExpanderTitleButton>
          {t('mitenTeenOmaisuusluettelon')}
        </ExpanderTitleButton>
        <ExpanderContent>
          <Paragraph>
            {t('mitaTarvitsenOmaisuusluettelonTekoon-kuvaus')}
          </Paragraph>

          <Paragraph mt="m" mb="m">
            <Text variant="bold">{t('omLtarvittavatTiedotListHeading')}</Text>
          </Paragraph>
          <DiscList>
            <li>{t('olTieto1')}</li>
            <li>{t('olTieto2')}</li>
            <li>{t('olTieto3')}</li>
            <li>{t('olTieto4')}</li>
            <li>{t('olTieto5')}</li>
            <li>{t('olTieto6')}</li>
          </DiscList>
          <Block mt="m" />

          <Paragraph>
            <Text variant="bold">{t('omaisuusluettelonAsiakirjat')}</Text>
          </Paragraph>
          <Paragraph>{t('omLtarvittavatAsiakirjatOhje')}</Paragraph>

          <Paragraph mt="m">
            {t('omLtarvittavatAsiakirjatListHeading')}
          </Paragraph>
          <Block mt="m" />
          <DiscList>
            <li>{t('olAsiakirjainfo1')}</li>
            <li>{t('olAsiakirjainfo2')}</li>
            <li>{t('olAsiakirjainfo3')}</li>
            <li>{t('olAsiakirjainfo4')}</li>
            <li>{t('olAsiakirjainfo5')}</li>
            <li>{t('olAsiakirjainfo6')}</li>
            <li>{t('olAsiakirjainfo7')}</li>
          </DiscList>
          <Block mt="m" />
          <ExternalLink
            data-test-id={mkLandingPageTestId('ohje-link')}
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
            href={omaisuusluetteloOhjeUrl(userStore.lang)}
          >
            {t('lueLisaaOmaisuusluettelosta')}
          </ExternalLink>
        </ExpanderContent>
      </Expander>

      <Block mt="m" />

      <Button
        data-test-id={mkLandingPageTestId('loginBottom')}
        onClick={() => (window.location.href = loginUri)}
        icon={<IconLogin />}
        fullWidth={false}
        role="link"
      >
        {t('loginButtonLabel')}
      </Button>
    </TiliNostokortti>
  )
})

const HeroText: React.FC<PropsWithChildren> = ({ children }) => (
  <Paragraph mb="m">
    <Text variant="lead" color="whiteBase">
      {children}
    </Text>
  </Paragraph>
)

const HeroBackground = styled.div`
  background-color: ${suomifiDesignTokens.colors.brandBase};
`
const MoneyIcon = styled(IconMoney).attrs(() => ({
  highlightColor: suomifiDesignTokens.colors.accentTertiaryDark1,
}))`
  height: 70px;
  width: 70px;
`

const LupaNostokortti = styled(InlineAlert)`
  &&& {
    background: ${suomifiDesignTokens.colors.whiteBase};
    border-top-color: ${suomifiDesignTokens.colors.depthBase};
    border-right-color: ${suomifiDesignTokens.colors.depthBase};
    border-bottom-color: ${suomifiDesignTokens.colors.depthBase};
    border-left-color: ${suomifiDesignTokens.colors.accentBase};
  }
`

const TiliNostokortti = styled(InlineAlert)`
  &&& {
    background: ${suomifiDesignTokens.colors.whiteBase};
    border-top-color: ${suomifiDesignTokens.colors.depthBase};
    border-right-color: ${suomifiDesignTokens.colors.depthBase};
    border-bottom-color: ${suomifiDesignTokens.colors.depthBase};
    border-left-color: ${suomifiDesignTokens.colors.accentTertiaryDark1};
  }
`

const LaptopIcon = styled(IconLaptopContent)`
  height: 70px;
  width: 70px;
`

const DiscList = styled.ul`
  list-style: disc outside;
  margin: 0 0 0 ${suomifiDesignTokens.spacing.l};
`

export default LandingPage
