import settings from 'asia-common/src/vtj/settings'
import { JSONArray } from 'common/src/vtj/types/json.type'

export enum AsiointiFeatureFlag {
  YHTEENVETOSIVU = 'yhteenvetosivu',
  TALLENNUS = 'tallennus',
  OMAISUUSLUETTELO = 'omaisuusluettelo',
  TABNAVIGATION = 'tabnavigation',
}

export const isAsiointiUiFeatureEnabled = (
  flag: AsiointiFeatureFlag
): boolean => {
  return (
    (settings.getOrNull('asiointiServer.enabledFeatures') as JSONArray) ?? []
  ).includes(flag)
}
