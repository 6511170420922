import keyMirror from 'keymirror'
import _ from 'lodash'

export const Retention = keyMirror({
  PYSYVA: null,
  SAILYTYSAIKA_0_VUOTTA: null,
  SAILYTYSAIKA_4_VUOTTA: null,
  SAILYTYSAIKA_5_VUOTTA: null,
  SAILYTYSAIKA_10_VUOTTA: null,
  SAILYTYSAIKA_20_VUOTTA: null,
  SAILYTYSAIKA_50_VUOTTA: null,
})
export type RetentionId = (typeof Retention)[keyof typeof Retention]
export const allRetentionIds = _.keys(Retention) as RetentionId[]

export const RetentionInYears: { [key in RetentionId]: number } = {
  [Retention.PYSYVA]: -1,
  [Retention.SAILYTYSAIKA_0_VUOTTA]: 0,
  [Retention.SAILYTYSAIKA_4_VUOTTA]: 4,
  [Retention.SAILYTYSAIKA_5_VUOTTA]: 5,
  [Retention.SAILYTYSAIKA_10_VUOTTA]: 10,
  [Retention.SAILYTYSAIKA_20_VUOTTA]: 20,
  [Retention.SAILYTYSAIKA_50_VUOTTA]: 50,
}

export const RetentionReason = keyMirror({
  TOIMINTATAPA: null,
  KANSALLISARKISTON_PAATOS: null,
})
export type RetentionReasonId = (typeof RetentionReason)[keyof typeof RetentionReason]
export const allRetentionReasonIds = _.keys(RetentionReason) as RetentionReasonId[]

export interface RetentionMetadata {
  defaultRetentionId: RetentionId
  retentionIds: RetentionId[]
  defaultRetentionReasonId?: RetentionReasonId
  retentionReasonIds?: RetentionReasonId[]
}
