/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostiliTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontElake } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import {
  commonEuroWithDecimalsOrUndefinedValidator,
  commonXssValidator,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { AsiointiTuloTyyppiBySubcategory } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import _ from 'lodash'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'

const ElaketulotForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tilirivit = getPaatostiliTilirivit()

  const mkNewElement = (): FrontElake =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      maksaja: '',
      yhteensa: undefined,
    })

  return (
    <section data-test-id={mkTulotMenotTestId('elaketulot-form')}>
      <Heading variant="h3">{t('elaketulotHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaElaketulot')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={ElaketuloListItem}
        state={tilirivit.tulot.elakkeet}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.TULOT}
        dataTestIdPrefix={mkTulotMenotTestId('elaketulot-form')}
        ModalContentComponent={ElaketuloModal}
        newEntry={mkNewElement}
        translationPrefix="elaketulot"
      />
    </section>
  )
}

const ElaketuloModal: FC<FormModalProps<FrontElake>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const elaketyypit = useMemo(
      () =>
        _.sortBy(
          Object.values(
            AsiointiTuloTyyppiBySubcategory(
              AsiointiLedgerAccountSubCategory.ELAKETULOT
            )
          ).map((value) => ({
            name: t(`incomeType_${value}`),
            value,
          })),
          'name'
        ),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('elaketulonTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={elaketyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountIncomeType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormTextInput
          data-test-id={createDataTestId('modal-maksaja')}
          labelText={t('maksaja')}
          value={entry.maksaja}
          required
          validate={commonXssValidator(t)}
          updateValue={(value) => setEntry({ ...entry, maksaja: value })}
        />

        <FormNumberInput
          data-test-id={createDataTestId('modal-tulotYhteensa')}
          labelText={t('tulotTilikaudenAikanaYhteensaEuroina')}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
      </div>
    )
  }
)

const ElaketuloListItem: FC<FormElementProps<FrontElake>> = observer(
  ({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const yhteensaEur = `${formatNumberWithComma(entry.yhteensa!)} €`

    return (
      <FormListInputExpander
        heading={t(`incomeType_${entry.tyyppi}`)}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        <Heading variant="h5">{t('maksaja')}</Heading>
        <Text>{entry.maksaja}</Text>
        <Block mt={verticalMarginToken} />

        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  }
)

export default observer(ElaketulotForm)
