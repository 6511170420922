import {
  AsiointiAddress,
  AsiointiCaretaker,
  AsiointiOtherPersons,
  AsiointiPrivatePerson,
} from 'tilintarkastus-common/src/vtj/types/asiointi-api.type'
import { AsiointiFileAttachment } from 'edunvalvonta-asiointi/src/vtj/asiointi/file/file-attachment.type'
import {
  AsiakirjaLiite,
  AsiointiAccountDataInput,
  AsiointiPropertyInventoryDataInput,
  Asuinpaikka,
  KayttoOmaisuus,
  PaamiehenOmaisuus,
  Palkkio,
  Pankkitili,
  SaannollinenTulo,
  Sopimus,
  Testamentti,
  Vakuutus,
  Verotustieto,
} from 'tilintarkastus-common/src/vtj/types/asiointi-account-data.type'
import {
  Arvopaperi,
  Edunvalvonnankulu,
  Elake,
  Elatusapu,
  Elinkustannus,
  Etuus,
  Huoneisto,
  Irtaimisto,
  Kayttotarkoitus,
  Kayttovara,
  Kiinteisto,
  Korkotulo,
  KuolinpesaOsakkuus,
  MuuTulo,
  MuuVara,
  MuuVelka,
  Myyntitulo,
  Osinkotulo,
  Palkka,
  Palvelumaksu,
  Pankkilaina,
  Pankkimaksu,
  Saatava,
  Takaus,
  TalletusTaiVakuutus,
  Tilirivit,
  Tyonantajakulu,
  Tyottomyysturva,
  Velanhoitomeno,
  Vero,
  VuokraTaiVastike,
  Vuokratulo,
  YhtioTaiYritysOsuus,
} from 'tilintarkastus-common/src/vtj/types/asiointi-account-rows.type'
import { Modify } from 'tilintarkastus-common/src/vtj/types/tilintarkastus-common.type'
import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'
import { AsiointiLedgerAccountIncomeType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-income-enum'
import { AsiointiLedgerAccountAssetsType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-assets-enum'
import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'
import {
  AsiointiHabitationPeriodType,
  AsiointiHabitationType,
  AsiointiLocaleId,
} from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

export enum AsiointiType {
  OMAISUUSLUETTELO = 'OMAISUUSLUETTELO',
  PAATOSTILI = 'PAATOSTILI',
}

export interface Lomake {
  locale: AsiointiLocaleId // TODO muuttuuko locale kun vaihdat kieltä?
  otherPersons: FrontAsiointiOtherPersons
  tili: Paatostili | Omaisuusluettelo
  caretaker: FrontCaretaker
}

export type FrontCaretaker = Modify<
  Omit<
    AsiointiCaretaker,
    | 'isAssetsRecipient'
    | 'role'
    | 'edunvalvontaSuhde'
    | 'tilivelvollisuusTypes'
    | 'hetu'
  >,
  {
    address: AsiointiAddress
  }
>

export type FrontPalkkio = Modify<
  Palkkio,
  {
    veloitatkoKulukorvauksia?: boolean
    veloitatkoPalkkiota?: boolean
    kululaskelma: FrontKululaskelma[]
    palkkioMaara?: number
    kulukorvausMaara?: number
  }
>

export type FrontAsuinpaikka = Modify<
  Asuinpaikka,
  {
    habitationType?: AsiointiHabitationType
    habitationPeriodType?: AsiointiHabitationPeriodType
  }
> & { id: string }

export type FrontSaannollinenTulo = Modify<
  SaannollinenTulo,
  {
    tyyppi?: AsiointiLedgerAccountIncomeType
    asiakirjat: FrontKululaskelma[]
  }
> & { id: string }

export type FrontElaketulotElinkustannuksiin = {
  elinkustannuksiinKaytettavatElaketulot?: number
  enKaytaElakettaElinkustannuksiin: boolean // ei ole undefinable koska checkbox
}

export type FrontSopimukset = Modify<
  Sopimus,
  {
    asiakirjat: FrontKululaskelma[]
  }
> & { id: string }

export type FrontVakuutukset = Modify<
  Vakuutus,
  {
    asiakirjat: FrontKululaskelma[]
    beginDate?: string
    endDate?: string
  }
> & { id: string }

export type FrontPankkitili = Modify<
  Pankkitili,
  {
    sopimukset: FrontAsiakirjaLiite[]
  }
> & { id: string; isKayttoOikeuksia?: boolean }

export type FrontAsiakirjaLiite = AsiakirjaLiite & AsiointiFileAttachment
export type FrontVerotustieto = Verotustieto & FrontAsiakirjaLiite
export type FrontKululaskelma = FrontAsiakirjaLiite

export type FrontAsiointiOtherPersons = Modify<
  AsiointiOtherPersons,
  {
    privatePersons: FrontPrivatePerson[]
  }
>

export type FrontPrivatePerson = AsiointiPrivatePerson & { id: string }

export type Paatostili = Omit<
  Modify<
    AsiointiAccountDataInput,
    {
      asiaType: AsiointiType.PAATOSTILI
      verotustiedot: FrontVerotustieto[]
      palkkio: FrontPalkkio
      hallintaoikeusSopimukset: FrontAsiakirjaLiite[]
      asuinpaikat: FrontAsuinpaikka[]
      sopimukset: FrontSopimukset[]
      vakuutukset: FrontVakuutukset[]
      tilirivit: FrontTilirivit
      edunvalvojanValtakirja: FrontAsiakirjaLiite[]
      saannollisetTulot: FrontSaannollinenTulo[]
      seurantaAsiavaatimusId: number
      hoitosuunnitelmat: FrontAsiakirjaLiite[]
      paamiehenOmaisuus: FrontPaamiehenOmaisuus
      isMuutMerkittavatToimet?: boolean
      isHallintaoikeuksia?: boolean
      elaketulotElinkustannuksiin: FrontElaketulotElinkustannuksiin
    }
  >,
  | 'guardianshipBeginDate'
  | 'guardianshipEndDate'
  | 'guardianshipEndReason'
  | 'accountingPeriodBeginDate'
  | 'accountingPeriodEndDate'
  | 'accountSummary'
  | 'seurantaAsiaVaatimusId'
>

export type Omaisuusluettelo = Omit<
  Modify<
    AsiointiPropertyInventoryDataInput,
    {
      asiaType: AsiointiType.OMAISUUSLUETTELO
      verotustiedot: FrontVerotustieto[]
      hallintaoikeusSopimukset: FrontAsiakirjaLiite[] // TODO refactor -> hallintaoikeudet, but must support välitallennettu lomake
      isHallintaoikeuksia?: boolean // TODO refactor -> hallintaoikeudet, but must support välitallennettu lomake
      mitenHallintaoikeuksistaOnSovittu?: string // TODO refactor -> hallintaoikeudet, but must support välitallennettu lomake
      asuinpaikat: FrontAsuinpaikka[] // TODO refactor -> asuminen, but must support välitallennettu lomake
      hasHoitosuunnitelma?: boolean // TODO refactor -> asuminen, but must support välitallennettu lomake
      asuuHuoltajanKanssa?: boolean // TODO refactor -> asuminen, but must support välitallennettu lomake
      huoltajienNimet?: string // TODO refactor -> asuminen, but must support välitallennettu lomake
      hoitosuunnitelmat: FrontAsiakirjaLiite[] // TODO refactor -> asuminen, but must support välitallennettu lomake
      sopimukset: FrontSopimukset[]
      vakuutukset: FrontVakuutukset[]
      saannollisetTulot: FrontSaannollinenTulo[]
      tilirivit: FrontOmaisuusTilirivit
      edunvalvojanValtakirja: FrontAsiakirjaLiite[]
      seurantaAsiavaatimusId: number
      paamiehenOmaisuus: FrontPaamiehenOmaisuus
      testamentti: FrontTestamentti
      isMuutMerkittavatToimet?: boolean
      hasKaytossaOlevaOmaisuus?: boolean // TODO refactor -> paamiehenOmaisuus, but must support välitallennettu lomake
      kaytossaOlevaOmaisuus: FrontKayttoOmaisuus[] // TODO refactor -> paamiehenOmaisuus, but must support välitallennettu lomake
      kayttovaraKaytanto?: string
      elaketulotElinkustannuksiin: FrontElaketulotElinkustannuksiin
    }
  >,
  | 'guardianshipBeginDate'
  | 'guardianshipEndDate'
  | 'guardianshipEndReason'
  | 'accountingPeriodBeginDate'
  | 'accountingPeriodEndDate'
  | 'accountSummary'
  | 'seurantaAsiaVaatimusId'
  | 'isAnnettuKayttovaroja'
  | 'hallintaoikeudet' // TODO USE
  | 'asuminen' // TODO USE
>

export type FrontTalletus = Modify<
  TalletusTaiVakuutus,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountAssetsType
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontArvopaperi = Modify<
  Arvopaperi,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountAssetsType
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontYhtioTaiYritysOsuus = Modify<
  YhtioTaiYritysOsuus,
  {
    id: string
    osakkeidenMaara?: number
    omistusosuusProsentteina?: number
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontHuoneisto = Modify<
  Huoneisto,
  {
    id: string
    kayttotarkoitus?: Kayttotarkoitus
    omistusosuusProsentteina?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
    vuokrasopimukset: FrontAsiakirjaLiite[]
  }
>

export type FrontKiinteisto = Modify<
  Kiinteisto,
  {
    id: string
    kayttotarkoitus?: Kayttotarkoitus
    omistusosuusProsentteina?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
    vuokrasopimukset: FrontAsiakirjaLiite[]
  }
>

export type FrontIrtaimisto = Modify<
  Irtaimisto,
  {
    id: string
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontSaatava = Modify<
  Saatava,
  {
    id: string
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontMuuVara = Modify<
  MuuVara,
  {
    id: string
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontVelat = {
  pankkilainat: FrontPankkilaina[]
  takaukset: FrontTakaus[]
  muutVelat: FrontMuuVelka[]
}

export type FrontMuuVelka = Modify<
  MuuVelka,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountDebtType
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontPankkilaina = Modify<
  Pankkilaina,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountDebtType
    arvoTilikaudenLopussaEur?: number
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontTakaus = Modify<
  Takaus,
  {
    id: string
    arvoTilikaudenLopussaEur?: number
    onkoTullutHenkilonMaksettavaksi?: boolean
    asiakirjat: FrontAsiakirjaLiite[]
  }
>

export type FrontVarat = {
  talletukset: FrontTalletus[]
  arvopaperit: FrontArvopaperi[]
  osuudetHenkiloYhtiossaJaYrityksissa: FrontYhtioTaiYritysOsuus[]
  huoneistot: FrontHuoneisto[]
  irtaimistot: FrontIrtaimisto[]
  kiinteistot: FrontKiinteisto[]
  muutVarat: FrontMuuVara[]
  saatavat: FrontSaatava[]
  osuudetKuolinpesissa: FrontKuolinpesaOsakkuus[]
}

export type FrontElake = Modify<
  Elake,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountIncomeType
    yhteensa?: number
  }
>

export type FrontPalkka = Modify<
  Palkka,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountIncomeType
    yhteensa?: number
    suostumus: FrontAsiakirjaLiite[]
  }
>

export type FrontEtuus = Modify<
  Etuus,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountIncomeType
    yhteensa?: number
  }
>

export type FrontTyottomyysturva = Modify<
  Tyottomyysturva,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountIncomeType
    yhteensa?: number
  }
>

export type FrontVuokratulo = Modify<
  Vuokratulo,
  {
    id: string
    yhteensa?: number
  }
>

export type FrontOsinkotulo = Modify<
  Osinkotulo,
  {
    id: string
    yhteensa?: number
  }
>

export type FrontKorkotulo = Modify<
  Korkotulo,
  {
    id: string
    yhteensa?: number
  }
>

export type FrontMyyntitulo = Modify<
  Myyntitulo,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountIncomeType
    yhteensa?: number
    tositteet: FrontAsiakirjaLiite[]
  }
>

export type FrontMuuTulo = Modify<
  MuuTulo,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountIncomeType
    yhteensa?: number
    tositteet: FrontAsiakirjaLiite[]
  }
>

export type FrontTulot = {
  elakkeet: FrontElake[]
  palkat: FrontPalkka[]
  etuudet: FrontEtuus[]
  tyottomyysturva: FrontTyottomyysturva[]
  vuokratulot: FrontVuokratulo[]
  osinkotulot: FrontOsinkotulo[]
  korkotulot: FrontKorkotulo[]
  myyntitulot: FrontMyyntitulo[]
  muutTulot: FrontMuuTulo[]
}

export type FrontKayttovara = Modify<
  Kayttovara,
  {
    id: string
    yhteensa?: number
    tositteet: FrontAsiakirjaLiite[]
  }
>

export type FrontElinkustannus = Modify<
  Elinkustannus,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontPalvelumaksu = Modify<
  Palvelumaksu,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontVuokraTaiVastike = Modify<
  VuokraTaiVastike,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontElatusapu = Modify<
  Elatusapu,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontPankkimaksu = Modify<
  Pankkimaksu,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontVero = Modify<
  Vero,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontTyonantajakulu = Modify<
  Tyonantajakulu,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontVelanhoitomeno = Modify<
  Velanhoitomeno,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
  }
>

export type FrontEdunvalvonnankulu = Modify<
  Edunvalvonnankulu,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
    tositteet: FrontAsiakirjaLiite[]
  }
>

export type FrontMuuMeno = Modify<
  Kayttovara,
  {
    id: string
    tyyppi?: AsiointiLedgerAccountExpenseType
    yhteensa?: number
    tositteet: FrontAsiakirjaLiite[]
  }
>

export type FrontKayttoOmaisuus = Modify<
  KayttoOmaisuus,
  {
    id: string
  }
>

export type FrontMenot = {
  kayttovarat: FrontKayttovara[]
  elinkustannukset: FrontElinkustannus[]
  palvelumaksut: FrontPalvelumaksu[]
  vuokratVastikkeet: FrontVuokraTaiVastike[]
  elatusavut: FrontElatusapu[]
  pankinPerimatMaksut: FrontPankkimaksu[]
  verot: FrontVero[]
  tyonantajakulut: FrontTyonantajakulu[]
  velanhoitomenot: FrontVelanhoitomeno[]
  edunvalvonnankulut: FrontEdunvalvonnankulu[]
  muutMenot: FrontMuuMeno[]
}

export type FrontKuolinpesaOsakkuus = Omit<
  Modify<
    KuolinpesaOsakkuus,
    {
      id: string
      vainajanKuolinpaiva?: string
      osakkuudenOsuus?: string
      arvoTilikaudenAlussaEur?: number
      arvoTilikaudenLopussaEur?: number
      asiakirjat: FrontAsiakirjaLiite[]
    }
  >,
  'osakkuudenOsuusProsentteina'
>

export type FrontTilirivit = Modify<
  Tilirivit,
  {
    velat: FrontVelat
    varat: FrontVarat
    tulot: FrontTulot
    menot: FrontMenot
  }
>

export type FrontOmaisuusTilirivit = Modify<
  Tilirivit,
  {
    velat: FrontVelat
    varat: FrontVarat
  }
>

export type FrontPaamiehenOmaisuus = Modify<
  PaamiehenOmaisuus,
  {
    pankkitilit: FrontPankkitili[]
    isMuutVarat?: boolean
  }
>

export type FrontTestamentti = Partial<Testamentti>
