import { Retention, RetentionMetadata, RetentionReason } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'

export const tilintarkastusJulkinenEiHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
  personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const tilintarkastusJulkinenHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
  personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const tilintarkastusSalassaPidettava25VuottaKohta23HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
  personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
  defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA,
  securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA],
  defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
  securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const tilintarkastusSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
  personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
  defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
  securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
  defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
  securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const tilintarkastusSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction: RestrictionMetadata =
  {
    defaultPublicityClassId: PublicityClass.SALAINEN,
    publicityClassIds: [PublicityClass.SALAINEN],
    defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
    securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
    defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
    personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
    defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
    securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA, SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA],
    defaultSecurityReasonIds: [
      SecurityReason.JULKL_621_1999_24_1_KOHTA_23,
      SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
    ],
    securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_23, SecurityReason.JULKL_621_1999_24_1_KOHTA_25],
    defaultViranomainenId: Viranomainen.DVV,
    viranomainenIds: [Viranomainen.DVV],
  }

export const tilintarkastus0VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_0_VUOTTA,
  // FIXME: keep PYSYVA until tilintarkastus is fixed, https://jira.dvv.fi/browse/OIVA-4650
  retentionIds: [Retention.SAILYTYSAIKA_0_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const tilintarkastus4VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_4_VUOTTA,
  // FIXME: keep PYSYVA until tilintarkastus is fixed, https://jira.dvv.fi/browse/OIVA-4650
  retentionIds: [Retention.SAILYTYSAIKA_4_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const tilintarkastus10VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_10_VUOTTA,
  // FIXME: keep PYSYVA until tilintarkastus is fixed, https://jira.dvv.fi/browse/OIVA-4650
  retentionIds: [Retention.SAILYTYSAIKA_10_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const tilintarkastus50VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_50_VUOTTA,
  // FIXME: keep PYSYVA until tilintarkastus is fixed, https://jira.dvv.fi/browse/OIVA-4650
  retentionIds: [Retention.SAILYTYSAIKA_50_VUOTTA, Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.TOIMINTATAPA,
  retentionReasonIds: [RetentionReason.TOIMINTATAPA],
}

export const tilintarkastusPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
  defaultRetentionReasonId: RetentionReason.KANSALLISARKISTON_PAATOS,
  retentionReasonIds: [RetentionReason.KANSALLISARKISTON_PAATOS],
}
