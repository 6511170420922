import { TFunction } from 'react-i18next'
import {
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseStringAsNumberWithDigits,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  getArvoAlussaString,
  getArvoLopussaString,
  getKayttotarkoitus,
  getKappalemaaraLopussaString,
  getKappalemaaraTilikaudenAlussaString,
  getOmistusosuus,
  getOsakkeidenMaara,
  getVelallinenString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { YhteenvetoGroupProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'

export const getPankkitalletuksetContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  const content: YhteenvetoGroupProps[] = varat.talletukset.map((tal) => {
    if (tal.yhteiskayttotili) {
      return {
        heading: `${t('tilityyppi_' + (tal.tyyppi || ''))}, ${
          tal.myontajanNimi
        }`,
        rows: [
          `${t('yhteiskayttoTili')}: ${t('kylla')}`,
          `${t('tilinumero')}: ${tal.vakuusTaiTilinumero}`,
          `${t('yhteiskayttoinenTiliSaldo')}: ${formatNumberWithComma(
            tal.kokonaissaldoLopussa || 0
          )} €`,
          tal.arvoTilikaudenAlussaEur !== undefined
            ? `${t('henkilonOsuusEuroinaAlussa')}: ${formatNumberWithComma(
                tal.arvoTilikaudenAlussaEur
              )} €`
            : undefined,
          tal.arvoTilikaudenLopussaEur !== undefined
            ? `${t('henkilonOsuusEuroinaLopussa')}: ${formatNumberWithComma(
                tal.arvoTilikaudenLopussaEur
              )} €`
            : undefined,
        ],
        attachments: tal.asiakirjat,
      }
    } else {
      return {
        heading: `${t('tilityyppi_' + (tal.tyyppi || ''))}, ${
          tal.myontajanNimi
        }`,
        rows: [
          `${t('tilinumero')}: ${tal.vakuusTaiTilinumero}`,
          getArvoAlussaString(t, asiaType, tal.arvoTilikaudenAlussaEur),
          getArvoLopussaString(t, asiaType, tal.arvoTilikaudenLopussaEur),
        ],
        attachments: tal.asiakirjat,
      }
    }
  })

  return content
}

export const getVaratContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.arvopaperit.map((ap) => {
    const rows = [
      getKappalemaaraTilikaudenAlussaString(t, ap.kappalemaaraTilikaudenAlussa),
      getKappalemaaraLopussaString(
        t,
        asiaType,
        ap.kappalemaaraTilikaudenLopussa
      ),
      getArvoAlussaString(t, asiaType, ap.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, ap.arvoTilikaudenLopussaEur),
    ]

    return {
      heading: `${t('arvopaperityyppi_' + (ap.tyyppi || ''))} ${ap.yhtionNimi}`,
      rows,
      attachments: ap.asiakirjat,
    }
  })
}

export const getOsuudetHenkiloyhtioissaContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.osuudetHenkiloYhtiossaJaYrityksissa.map((os) => {
    return {
      heading: `${os.yhtionNimi}`,
      rows: [
        getOsakkeidenMaara(os.osakkeidenMaara, t),
        getOmistusosuus(os.omistusosuusProsentteina, t),
        getArvoAlussaString(t, asiaType, os.arvoTilikaudenAlussaEur),
        getArvoLopussaString(t, asiaType, os.arvoTilikaudenLopussaEur),
      ],
      attachments: os.asiakirjat,
    }
  })
}

export const getHuoneistotContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.huoneistot.map((h) => {
    return {
      heading: `${h.yhtionNimi},  ${t('osakkeidenNumerot')} ${
        h.osakkeidenNumerot
      }`,
      rows: [
        getKayttotarkoitus(t, h),
        getOmistusosuus(h.omistusosuusProsentteina, t),
        getArvoAlussaString(t, asiaType, h.arvoTilikaudenAlussaEur),
        getArvoLopussaString(t, asiaType, h.arvoTilikaudenLopussaEur),
      ],
      attachments: [...h.asiakirjat, ...h.vuokrasopimukset],
    }
  })
}

export const getKiinteistotContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.kiinteistot.map((k) => {
    return {
      heading: `${k.kiinteistonNimi},  ${k.kiinteistonTunnus}`,
      rows: [
        getKayttotarkoitus(t, k),
        getOmistusosuus(k.omistusosuusProsentteina, t),
        getArvoAlussaString(t, asiaType, k.arvoTilikaudenAlussaEur),
        getArvoLopussaString(t, asiaType, k.arvoTilikaudenLopussaEur),
      ],
      attachments: [...k.asiakirjat, ...k.vuokrasopimukset],
    }
  })
}

export const getIrtaimistoContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.irtaimistot.map((i) => {
    return {
      heading: i.nimi,
      rows: [
        getArvoAlussaString(t, asiaType, i.arvoTilikaudenAlussaEur),
        getArvoLopussaString(t, asiaType, i.arvoTilikaudenLopussaEur),
      ],
      attachments: i.asiakirjat,
    }
  })
}

export const getSaatavatVaratContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.saatavat.map((s) => ({
    heading: getVelallinenString(t, s.velallisenEtunimi, s.velallisenSukunimi),
    rows: [
      getArvoAlussaString(t, asiaType, s.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, s.arvoTilikaudenLopussaEur),
    ],
    attachments: s.asiakirjat,
  }))
}

export const getMuutVaratContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.muutVarat.map((mv) => ({
    heading: mv.nimi,
    rows: [
      getArvoAlussaString(t, asiaType, mv.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, mv.arvoTilikaudenLopussaEur),
    ],
    attachments: mv.asiakirjat,
  }))
}

export const getKuolinpesatContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: { varat },
    asiaType,
  } = account

  return varat.osuudetKuolinpesissa.map((os) => ({
    heading: `${t('kuolinpesa')} ${os.vainajanEtunimi} ${os.vainajanSukunimi}`,
    rows: [
      parseStringAsNumberWithDigits(os.osakkuudenOsuus, 'two')
        ? `${t('osakkuudenOsuus')} ${os.osakkuudenOsuus} %`
        : os.osakkuudenOsuus,
      os.vainajanKuolinpaiva &&
        `${t('vainajanKuolinpaiva')} ${formatDateAsLocal(
          os.vainajanKuolinpaiva
        )}`,
      getArvoAlussaString(t, asiaType, os.arvoTilikaudenAlussaEur),
      getArvoLopussaString(t, asiaType, os.arvoTilikaudenLopussaEur),
    ],
    attachments: os.asiakirjat,
  }))
}
