import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import { Retention } from 'asia-common/src/vtj/koodisto/retention-enums'
import { PublicityClass } from 'asia-common/src/vtj/koodisto/restriction-enums'

export const sukunimenmuutosLuokittelematonMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.NIMENMUUTOSHAKEMUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.LISASELVITYS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIRKATODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SUOSTUMUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_PAATOS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUNTO_SOSIAALITOIMELTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.SALAINEN,
        publicityClassIds: [PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS_ASIAKKAALTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.HENKILON_NIMI_LAKI_VAATIMUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO_NIMILAUTAKUNNALLE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.NIMILAUTAKUNNAN_LAUSUNTO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VASTINEPYYNTO_ASIAKKAALLE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.VASTINE_ASIAKKAALTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUISTUTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS_NIMENMUUTOKSESTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c0420101-ce83-465f-ba15-8094bd9ee3e2', 'bb9f436a-6eb3-4dd2-af23-a71c6c5387db'],
      defaultToivoAsiakirjaTypeId: 'c0420101-ce83-465f-ba15-8094bd9ee3e2',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      // TODO VAAKA-5503 Change to LAADITTU
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6dae0105-29ea-4bfa-bdbd-3e6ae54f304d'],
      defaultToivoAsiakirjaTypeId: '6dae0105-29ea-4bfa-bdbd-3e6ae54f304d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUISTIO,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.JULKINEN,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SUKUSELVITYS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOTODISTUKSESTA_TAI_PASSISTA,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SYNTYMATODISTUS,
      restrictionMetadata: {
        defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
        publicityClassIds: [PublicityClass.JULKINEN, PublicityClass.OSITTAIN_SALASSAPIDETTAVA, PublicityClass.SALAINEN],
      },
      retentionMetadata: {
        defaultRetentionId: Retention.PYSYVA,
        retentionIds: [Retention.PYSYVA],
      },
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.ODOTTAA_NIMILAUTAKUNTAA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_MAKSUA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_SUOSTUMUSTA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TIEDOTETTAVANA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_MUU_SYY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_AUTOMAATTISTA_PAATOKSENTEKOA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.AUTOMAATTINEN_PAATOKSENTEKO_EPAONNISTUNUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
      RatkaisuType.RAUENNUT,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
      huomioMetadata: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ITSEPALVELU],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
