import React, { FC, useEffect } from 'react'
import { observer } from 'mobx-react'
import {
  valitseHenkiloByPersonId,
  useEvtvStore,
  useHenkilot,
  initializeEvtvStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import {
  Block,
  Button,
  Heading,
  IconChevronRight,
  IconInfoFilled,
  IconUserProfile,
  InlineAlert,
  LoadingSpinner,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import {
  BorderedContentBlock,
  CenteredWidthLimitedDiv,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import { AsiointiHenkilo } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { mkPaamiehenValintaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import { OverviewButton } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiesFrontPage'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { useAsiointiUser } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'

const PaamiehenValintaPage = observer(() => {
  const [t] = useTranslation()
  const user = useAsiointiUser()
  const { isLoading, isError } = useEvtvStore()
  const isTablet = useDeviceContext().tablet
  const navigate = useNavigate()
  const verticalMarginToken = isTablet ? 'xl' : 's'

  useEffect(() => {
    if (user) {
      void initializeEvtvStore()
    }
  }, [user])

  return (
    <CenteredWidthLimitedDiv paddingX paddingY>
      <Heading variant="h1">{t('henkilonValinta')}</Heading>
      <BorderedContentBlock mt={'m'} padding={'xl'}>
        <HeadingContainer>
          <UserProfileGigaXL />
          <Heading
            id={headingId}
            data-test-id={headingId}
            variant="h3"
            as={'h2'}
          >
            {t('valitseHenkiloAsiointi')}
          </Heading>
        </HeadingContainer>
        <Block mt={verticalMarginToken} />
        {isLoading && <LoadingSpinner text={t('ladataan')} textAlign="right" />}
        {!isLoading && !isError && <PaamiesListaus />}
        {!isLoading && isError && (
          <InlineAlert
            status="error"
            data-test-id={mkPaamiehenValintaTestId('haku-virhe')}
          >
            <Text variant="body" smallScreen>
              {t('virheHenkilohaussa')}
            </Text>
          </InlineAlert>
        )}

        <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        <Text variant={'bold'}>{t('lupaJosHaluatAsioidaPuolesta')}</Text>
        <Block mt={verticalMarginToken} />
        <OverviewButton
          data-test-id={mkPaamiehenValintaTestId('haeLupaa')}
          onClick={() => navigate('/luvat/hakemus/hae')}
          fullWidth={false}
          role="link"
          variant="secondary"
        >
          {t('overviewLupaInfoActionButton')}
        </OverviewButton>
      </BorderedContentBlock>
    </CenteredWidthLimitedDiv>
  )
})

interface PaamiesLinkProps {
  henkilo: AsiointiHenkilo
  'data-test-id': string
}

const PaamiesSelection: FC<PaamiesLinkProps> = observer(
  ({ henkilo, 'data-test-id': dataTestId }) => {
    const { personId, lastname, firstnames } = henkilo
    const navigate = useNavigate()
    const handlePaamiesSelection = () => {
      valitseHenkiloByPersonId(personId)
      navigate(getAsioiPathByPersonId(personId))
    }
    const formattedName = `${lastname}, ${firstnames}`

    return (
      <PaamiesLink
        onClick={handlePaamiesSelection}
        data-test-id={dataTestId}
        aria-labelledby={headingId}
      >
        <Text variant="bold" color="highlightBase">
          {formattedName}
        </Text>
      </PaamiesLink>
    )
  }
)

const PaamiesListaus = observer(() => {
  const [t] = useTranslation()
  const henkilot = useHenkilot()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 's'

  if (!henkilot?.length) {
    return (
      <Text data-test-id={mkPaamiehenValintaTestId('tyhja')}>
        {t('eiPuolestaAsiointia')}
      </Text>
    )
  }
  return (
    <>
      {henkilot.map((henkilo, index) => (
        <PaamiesSelection
          key={henkilo.personId}
          henkilo={henkilo}
          data-test-id={mkPaamiehenValintaTestId('paamies', index)}
        />
      ))}
      <Block mt={verticalMarginToken} />
      <InfoTextContainer>
        <HighlightedIconInfoFilledL />
        <Text smallScreen>{t('josHaetLupaaInfo')}</Text>
      </InfoTextContainer>
    </>
  )
})

const PaamiesLink = styled(Button).attrs(() => ({
  variant: 'secondary',
  role: 'link',
  iconRight: <IconChevronRightL />,
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 700px;
  height: ${suomifiDesignTokens.spacing.xxxl};
  margin: 0 0 -1px 0;
`

const IconChevronRightL = styled(IconChevronRight)`
  &&& {
    width: 24px;
    height: 24px;
  }
`
const HighlightedIconInfoFilledL = styled(IconInfoFilled)`
  &&& {
    color: ${suomifiDesignTokens.colors.highlightBase};
    width: 24px;
    height: 24px;
  }
`

const UserProfileGigaXL = styled(IconUserProfile)`
  &&& {
    min-width: 70px;
    height: 70px;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${suomifiDesignTokens.spacing.m};
`

const InfoTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${suomifiDesignTokens.spacing.xs};
`

const headingId = mkPaamiehenValintaTestId('heading')

export default PaamiehenValintaPage
