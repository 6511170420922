import React, { useEffect, useState } from 'react'
import { Outlet, Route, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import {
  useAsiointiUser,
  useAsiointiUserStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { I18nextProvider } from 'react-i18next'
import {
  OMAISUUSLUETTELO_ROUTES,
  OmaisuusluetteloForms,
  omaisuusluetteloFormsToStates,
  PAATOSTILI_ROUTES,
  PaatostiliForms,
  paatostiliFormsToStates,
  TilintarkastusForm,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { initTilinpaatosAsiointiI18n } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/localization/i18next'
import { suomifiDesignTokens } from 'suomifi-ui-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import styled from 'styled-components'
import { centeredWidthLimited } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import ValmisPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/ValmisPage'
import {
  getTilintarkastusStore,
  initTilintarkastusStore,
  isTilintarkastusStoreInitialized,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isInsideBrowser } from 'common/src/vtj/browser/browser-util'
import UnexpectedError from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/UnexpectedError'
import PageLoading from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageLoading'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useRedirectUnauthenticatedToLogIn } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/login-redirect'
import YleiskatsausPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yleiskatsaus/YleiskatsausPage'
import { initCountryStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/country.store'
import PalkkioPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/PalkkioPage'
import PaatostiliOmaisuusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/omaisuus/OmaisuusPage'
import { useValittuEvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import TulotMenotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/tulotMenot/TulotMenotPage'
import YhteystiedotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/yhteystiedot/YhteystiedotPage'
import OmaisuusluetteloApplicationPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/OmaisuusluetteloApplicationPage'
import ToimintakykyAsuminenPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/ToimintakykyAsuminenPage'
import YhteenvetoPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/YhteenvetoPage'
import { getTiliDraftBySeurantaasiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import OmassaKaytossaOmaisuusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/OmassaKaytossaOmaisuusPage'
import PaatostiliApplicationPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/PaatostiliApplicationPage'
import { DraftData } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import OmaisuusluetteloAloitusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/AloitusPage'
import PaatostiliAloitusPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/paatostili/AloitusPage'
import ElakkeetJaEtuudetPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/ElakkeetJaEtuudetPage'
import KayttovaratPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/KayttovaratPage'
import SopimuksetJaVakuutuksetPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/SopimuksetJaVakuutuksetPage'
import MuutMerkittavatToimetPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/MuutMerkittavatToimetPage'
import OmaisuusluetteloYhteystiedotPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/YhteystiedotPage'
import VaratPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/VaratPage'
import VelatPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/VelatPage'
import OmaisuusluetteloYhteenvetoPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/YhteenvetoPage'
import OmaisuusluetteloValmisPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/ValmisPage'
import OmaisuusluetteloValtakirjaPage from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/omaisuusluettelo/ValtakirjaPage'

const canAccessPath = (path: string): boolean => {
  try {
    const { submitStatus, lomake } = getTilintarkastusStore()
    if (submitStatus === 'submitted') {
      return lomake.tili.asiaType === 'OMAISUUSLUETTELO'
        ? path === OMAISUUSLUETTELO_ROUTES.VALMIS
        : path === PAATOSTILI_ROUTES.VALMIS
    } else if (submitStatus === 'in-progress') {
      return lomake.tili.asiaType === 'OMAISUUSLUETTELO'
        ? path === OMAISUUSLUETTELO_ROUTES.YHTEENVETO
        : path === PAATOSTILI_ROUTES.YHTEENVETO
    } else {
      return true
    }
  } catch {
    return true
  }
}

const overridePath = (): string => {
  try {
    const { submitStatus, lomake } = getTilintarkastusStore()
    if (submitStatus === 'submitted') {
      return lomake.tili.asiaType === 'OMAISUUSLUETTELO'
        ? OMAISUUSLUETTELO_ROUTES.VALMIS
        : PAATOSTILI_ROUTES.VALMIS
    } else {
      return '/'
    }
  } catch {
    return '/'
  }
}

export const TilintarkastusAsiointiApp: React.FC<{
  forms: Record<string, TilintarkastusForm>
}> = observer(({ forms }) => {
  const { lang } = useAsiointiUserStore()
  const i18n = initTilinpaatosAsiointiI18n(lang)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const evtvVaatimus = useValittuEvtvVaatimus()
  const [isLoading, setLoading] = useState(
    !isTilintarkastusStoreInitialized(evtvVaatimus.seurantaAsiavaatimusId)
  )
  const [initError, setInitError] = useState<Error | undefined>()
  const user = useAsiointiUser()

  const isRedirected = useRedirectUnauthenticatedToLogIn()
  if (isRedirected) {
    return null
  }

  useEffect(() => {
    const initFormStateWithDraft = async ({ visitedSteps }: DraftData) => {
      for (const step of visitedSteps) {
        for (const [path, form] of Object.entries(forms)) {
          if (path === step) {
            // Submit form että saadaan navigointi-wizardin tilaan "valmis"
            await form.handleSubmit(() => undefined)()
          }
        }
      }
    }
    const initAndHandleErrors = async () => {
      setLoading(true)
      try {
        const tiliDraft = getTiliDraftBySeurantaasiavaatimus(
          evtvVaatimus.seurantaAsiavaatimusId
        )
        initTilintarkastusStore(user, evtvVaatimus, tiliDraft?.data, lang)
        await initCountryStore()
        if (tiliDraft) {
          await initFormStateWithDraft(tiliDraft?.data)
        }
      } catch (err) {
        setInitError(
          err instanceof Error
            ? err
            : new Error('Tilintarkastus initialization failed')
        )
        setLoading(false)
      }
    }

    const redirectIfCannotAccessPath = () => {
      if (!canAccessPath(pathname)) {
        const newPath = overridePath()
        setLoading(true)
        navigate(newPath)
      } else {
        setLoading(false)
      }
    }

    // useEffect is only called in a browser(-like) environment. If asiointi store initialization
    // is ever done server-side, we must take care to initialize the store from scratch for every request
    // to avoid mixing up different users' data (and the same user's data from different devices/tabs).
    if (
      (!isInsideBrowser() ||
        !isTilintarkastusStoreInitialized(
          evtvVaatimus.seurantaAsiavaatimusId
        )) &&
      !initError
    ) {
      void initAndHandleErrors()
      redirectIfCannotAccessPath()
    } else {
      redirectIfCannotAccessPath()
    }
  }, [initError, navigate, pathname, evtvVaatimus, lang, user, forms])

  if (initError) {
    return (
      <UnexpectedError
        error={initError}
        data-test-id={mkTestId('init-error')}
      />
    )
  }

  if (isLoading) {
    return <PageLoading data-test-id={mkTestId('loader')} />
  }

  return (
    <I18nextProvider i18n={i18n}>
      <App data-test-id={mkTestId('app')}>
        <Main>
          <MainContent>
            <ApplicationDetails>
              <Outlet />
            </ApplicationDetails>
          </MainContent>
        </Main>
      </App>
    </I18nextProvider>
  )
})

export const paatostiliAsiointiRoutes: (
  forms: PaatostiliForms
) => React.ReactNode = (forms) => {
  const formStates = paatostiliFormsToStates(forms)
  return (
    <>
      <Route
        path={PAATOSTILI_ROUTES.TEE_PAATOSTILI}
        element={<PaatostiliApplicationPage forms={formStates} />}
      >
        <Route
          path={PAATOSTILI_ROUTES.TEE_PAATOSTILI}
          element={
            <PaatostiliAloitusPage
              form={forms[PAATOSTILI_ROUTES.TEE_PAATOSTILI]}
            />
          }
        />
        <Route
          path={PAATOSTILI_ROUTES.YLEISKATSAUS}
          element={
            <YleiskatsausPage form={forms[PAATOSTILI_ROUTES.YLEISKATSAUS]} />
          }
        />
        <Route
          path={PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT}
          element={
            <PaatostiliOmaisuusPage
              form={forms[PAATOSTILI_ROUTES.OMAISUUS_JA_VELAT]}
            />
          }
        />
        <Route
          path={PAATOSTILI_ROUTES.TULOT_JA_MENOT}
          element={
            <TulotMenotPage form={forms[PAATOSTILI_ROUTES.TULOT_JA_MENOT]} />
          }
        />
        <Route
          path={PAATOSTILI_ROUTES.PALKKIO}
          element={<PalkkioPage form={forms[PAATOSTILI_ROUTES.PALKKIO]} />}
        />
        <Route
          path={PAATOSTILI_ROUTES.YHTEYSTIEDOT}
          element={
            <YhteystiedotPage form={forms[PAATOSTILI_ROUTES.YHTEYSTIEDOT]} />
          }
        />
        <Route
          path={PAATOSTILI_ROUTES.YHTEENVETO}
          element={<YhteenvetoPage forms={formStates} />}
        />
        <Route path={PAATOSTILI_ROUTES.VALMIS} Component={ValmisPage} />
      </Route>
    </>
  )
}
export const omaisuusluetteloAsiointiRoutes: (
  forms: OmaisuusluetteloForms
) => React.ReactNode = (forms) => {
  const formStates = omaisuusluetteloFormsToStates(forms)
  return (
    <>
      <Route
        path={OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO}
        element={<OmaisuusluetteloApplicationPage forms={formStates} />}
      >
        <Route
          path={OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO}
          element={
            <OmaisuusluetteloAloitusPage
              form={forms[OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT}
          element={
            <ToimintakykyAsuminenPage
              form={forms[OMAISUUSLUETTELO_ROUTES.TOIMINTAKYKY_JA_ASUMISTIEDOT]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS}
          element={
            <OmassaKaytossaOmaisuusPage
              form={
                forms[OMAISUUSLUETTELO_ROUTES.OMASSA_KAYTOSSA_OLEVA_OMAISUUS]
              }
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.VARAT}
          element={<VaratPage form={forms[OMAISUUSLUETTELO_ROUTES.VARAT]} />}
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.VELAT}
          element={<VelatPage form={forms[OMAISUUSLUETTELO_ROUTES.VELAT]} />}
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.ELAKKEET_JA_ETUUDET}
          element={
            <ElakkeetJaEtuudetPage
              form={forms[OMAISUUSLUETTELO_ROUTES.ELAKKEET_JA_ETUUDET]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.KAYTTOVARAT}
          element={
            <KayttovaratPage
              form={forms[OMAISUUSLUETTELO_ROUTES.KAYTTOVARAT]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET}
          element={
            <SopimuksetJaVakuutuksetPage
              form={forms[OMAISUUSLUETTELO_ROUTES.SOPIMUKSET_JA_VAKUUTUKSET]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.MUUT_MERKITTAVAT_TOIMET}
          element={
            <MuutMerkittavatToimetPage
              form={forms[OMAISUUSLUETTELO_ROUTES.MUUT_MERKITTAVAT_TOIMET]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.YHTEYSTIEDOT}
          element={
            <OmaisuusluetteloYhteystiedotPage
              form={forms[OMAISUUSLUETTELO_ROUTES.YHTEYSTIEDOT]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.VALTAKIRJA}
          element={
            <OmaisuusluetteloValtakirjaPage
              form={forms[OMAISUUSLUETTELO_ROUTES.VALTAKIRJA]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.YHTEENVETO}
          element={
            <OmaisuusluetteloYhteenvetoPage
              forms={formStates}
              form={forms[OMAISUUSLUETTELO_ROUTES.YHTEENVETO]}
            />
          }
        />
        <Route
          path={OMAISUUSLUETTELO_ROUTES.VALMIS}
          element={<OmaisuusluetteloValmisPage />}
        />
      </Route>
    </>
  )
}

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};
`

const App = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`

const Main = styled.main`
  ${centeredWidthLimited};

  padding: ${suomifiDesignTokens.spacing.s} 0;

  @media ${device.tablet} {
    padding: ${suomifiDesignTokens.spacing.xl} ${suomifiDesignTokens.spacing.xl};
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`
