import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const getContent = (
  tili: Omaisuusluettelo | Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const content = []
  const isMuutVarat = (tili as Omaisuusluettelo).paamiehenOmaisuus?.isMuutVarat
  const muutVarat = (tili as Omaisuusluettelo).paamiehenOmaisuus?.muutVarat
  content.push({
    heading: t('saakoHenkiloOmaanKayttoonsaMuitaVaroja'),
    rows: [
      isMuutVarat ? t('kylla') : isMuutVarat === false ? t('ei') : undefined,
    ],
  })
  if (isMuutVarat) {
    content.push({
      heading: t('mitaMuitaVarojaHenkiloSaaOmaanKayttoon'),
      rows: [muutVarat],
    })
  }
  return content
}

const MuutVarat: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('muutOmassaKaytossaOlevatVarat')}
      </Heading>
      <YhteenvetoGroups groups={getContent(tili, t)} />
    </>
  )
})

export default MuutVarat
