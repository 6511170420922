import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  getEdunvalvonnanKulutContent,
  getElatusavutContent,
  getElinkustannuksetContent,
  getKayttovaratContent,
  getMuutMenotContent,
  getPalvelumaksutContent,
  getPankinPerimatMaksutContent,
  getTyonantajakulutContent,
  getVelanhoitomenotContent,
  getVerotContent,
  getVuokratVastikkeetContent,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/menot.util'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const Menot: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getPaatostili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('menot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('menotHeading')}
      </Heading>

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('kayttovaratHeading')}
      </Heading>
      <YhteenvetoGroups groups={getKayttovaratContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('elinkustannuksetHeading')}
      </Heading>
      <YhteenvetoGroups groups={getElinkustannuksetContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('palvelumaksutHeading')}
      </Heading>
      <YhteenvetoGroups groups={getPalvelumaksutContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('vuokratVastikkeetHeading')}
      </Heading>
      <YhteenvetoGroups groups={getVuokratVastikkeetContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('elatusavutHeading')}
      </Heading>
      <YhteenvetoGroups groups={getElatusavutContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('pankkimaksutHeading')}
      </Heading>
      <YhteenvetoGroups groups={getPankinPerimatMaksutContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('verotHeading')}
      </Heading>
      <YhteenvetoGroups groups={getVerotContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('tyonantajakulutHeading')}
      </Heading>
      <YhteenvetoGroups groups={getTyonantajakulutContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('velanhoitomenotHeading')}
      </Heading>
      <YhteenvetoGroups groups={getVelanhoitomenotContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('edunvalvonnankulutHeading')}
      </Heading>
      <YhteenvetoGroups groups={getEdunvalvonnanKulutContent(tili, t)} />

      <Heading variant="h4" mb={verticalMarginToken}>
        {t('muutMenotHeading')}
      </Heading>
      <YhteenvetoGroups groups={getMuutMenotContent(tili, t)} />
    </section>
  )
})

export default Menot
