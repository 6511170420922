/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useMemo } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkTulotMenotTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { getPaatostiliTilirivit } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontEdunvalvonnankulu } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { FormListInputExpander } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { AsiointiMenoTyyppiBySubcategory } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import _ from 'lodash'
import {
  AsiointiLedgerAccountCategory,
  AsiointiLedgerAccountSubCategory,
  AsiointiLedgerAccountType,
} from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account.enum'
import { AsiointiLedgerAccountExpenseType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-expense-enum'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'

const EdunvalvonnankulutForm: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tilirivit = getPaatostiliTilirivit()

  const mkNewElement = (): FrontEdunvalvonnankulu =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      yhteensa: undefined,
      tositteet: [],
    })

  return (
    <section data-test-id={mkTulotMenotTestId('edunvalvonnankulut-form')}>
      <Heading variant="h3">{t('edunvalvonnankulutHeading')}</Heading>
      <Block mt={verticalMarginToken} />
      <Paragraph>{t('ohjeIlmoitaEdunvalvonnankulut')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={EdunvalvonnankuluListItem}
        state={tilirivit.menot.edunvalvonnankulut}
        asiointiLedgerAccountCategory={AsiointiLedgerAccountCategory.MENOT}
        dataTestIdPrefix={mkTulotMenotTestId('edunvalvonnankulut-form')}
        ModalContentComponent={EdunvalvonnankuluModal}
        newEntry={mkNewElement}
        translationPrefix="edunvalvonnankulut"
      />
    </section>
  )
}

const EdunvalvonnankuluModal: FC<FormModalProps<FrontEdunvalvonnankulu>> =
  observer(({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()

    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const yhteensaHintText = (
      tyyppi: AsiointiLedgerAccountExpenseType | undefined
    ) => {
      switch (tyyppi) {
        case AsiointiLedgerAccountExpenseType.TILINTARKASTUS:
          return t('edunvalvonnankuluTilintarkastusHintText')
        case AsiointiLedgerAccountExpenseType.PAATOKSET:
          return t('edunvalvonnankuluPaatoksetHintText')
        case AsiointiLedgerAccountExpenseType.MUUT_MAKSUT:
          return t('edunvalvonnankuluMuutMaksutHintText')
        default:
          return undefined
      }
    }

    const edunvalvonnankulutyypit = useMemo(
      () =>
        _.sortBy(
          Object.values(
            AsiointiMenoTyyppiBySubcategory(
              AsiointiLedgerAccountSubCategory.EDUNVALVONNAN_KULUT
            )
          ).map((value) => ({
            name: t(`expenseType_${value}`),
            value,
          })),
          'name'
        ),
      [t]
    )

    return (
      <div>
        <FormDropdown
          labelText={t('edunvalvonnanKulunTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={edunvalvonnankulutyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountExpenseType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        <FormNumberInput
          data-test-id={createDataTestId('modal-menotYhteensa')}
          labelText={t('menotTilikaudenAikanaYhteensaEuroina')}
          hintText={yhteensaHintText(entry.tyyppi)}
          digitMode="two"
          required
          value={entry.yhteensa}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              yhteensa: value,
            })
          }
        />
        {(entry.tyyppi === AsiointiLedgerAccountType.EDUNVALVOJAN_KULUKORVAUS ||
          entry.tyyppi ===
            AsiointiLedgerAccountExpenseType.EDUNVALVOJAN_PALKKIO) && (
          <FormAttachmentFileBox
            observableAttachments={entry.tositteet}
            asiakirjaTypeId={TiliAsiakirjaType.TOSITE}
            data-test-id={createDataTestId('modal-asiakirja')}
            text={t('edunvalvonnankulutAsiakirjaInfo')}
            title={t('palkkioJaKululasku')}
          />
        )}
      </div>
    )
  })

const EdunvalvonnankuluListItem: FC<FormElementProps<FrontEdunvalvonnankulu>> =
  observer(({ createDataTestId, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const yhteensaEur = `${formatNumberWithComma(entry.yhteensa!)} €`

    return (
      <FormListInputExpander
        heading={t(`expenseType_${entry.tyyppi}`)}
        headingRight={yhteensaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        showSubHeadingWhileOpen
      >
        {entry.tositteet.length > 0 && (
          <>
            <Block mt={verticalMarginToken} />
            <Heading variant="h5">{t('tositteet')}</Heading>
            <AttachmentList attachments={entry.tositteet} />
            <Block mt={verticalMarginToken} />
          </>
        )}
        <Block mt={verticalMarginToken} />
      </FormListInputExpander>
    )
  })

export default observer(EdunvalvonnankulutForm)
