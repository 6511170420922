import { TFunction } from 'react-i18next'
import { Paatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getIncomeForAccountingPeriod } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { YhteenvetoGroupProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'

export const getElakkeetContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { elakkeet },
    },
  } = tili

  return elakkeet.map((el) => {
    return {
      heading: t(`incomeType_${el.tyyppi}`),
      rows: [el.maksaja, getIncomeForAccountingPeriod(t, el.yhteensa)],
    }
  })
}

export const getPalkatContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { palkat },
    },
  } = tili

  return palkat.map((pal) => {
    return {
      heading: t(`incomeType_${pal.tyyppi}`),
      rows: [pal.maksaja, getIncomeForAccountingPeriod(t, pal.yhteensa)],
      attachments: pal.suostumus,
    }
  })
}

export const getEtuudetContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { etuudet },
    },
  } = tili

  return etuudet.map((et) => {
    return {
      heading: t(`incomeType_${et.tyyppi}`),
      rows: [et.maksaja, getIncomeForAccountingPeriod(t, et.yhteensa)],
    }
  })
}

export const getTyottomyysturvaContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { tyottomyysturva },
    },
  } = tili

  return tyottomyysturva.map((tt) => {
    return {
      heading: t(`incomeType_${tt.tyyppi}`),
      rows: [tt.maksaja, getIncomeForAccountingPeriod(t, tt.yhteensa)],
    }
  })
}

export const getVuokratContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { vuokratulot },
    },
  } = tili

  return vuokratulot.map((vt) => {
    return {
      heading: `${vt.nimi}, ${vt.tunnus}`,
      rows: [getIncomeForAccountingPeriod(t, vt.yhteensa)],
    }
  })
}

export const getOsingotContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { osinkotulot },
    },
  } = tili

  return osinkotulot.map((ot) => {
    return {
      heading: ot.maksaja,
      rows: [getIncomeForAccountingPeriod(t, ot.yhteensa)],
    }
  })
}

export const getKorkotulotContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { korkotulot },
    },
  } = tili

  return korkotulot.map((kt) => {
    return {
      heading: kt.maksaja,
      rows: [getIncomeForAccountingPeriod(t, kt.yhteensa)],
    }
  })
}

export const getMyyntitulotContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { myyntitulot },
    },
  } = tili

  return myyntitulot.map((mt) => {
    return {
      heading: t(`incomeType_${mt.tyyppi}`),
      rows: [getIncomeForAccountingPeriod(t, mt.yhteensa)],
      attachments: mt.tositteet,
    }
  })
}

export const getMuutTulotContent = (
  tili: Paatostili,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const {
    tilirivit: {
      tulot: { muutTulot },
    },
  } = tili

  return muutTulot.map((mt) => {
    return {
      heading: t(`incomeType_${mt.tyyppi}`),
      rows: [mt.maksaja, getIncomeForAccountingPeriod(t, mt.yhteensa)],
      attachments: mt.tositteet,
    }
  })
}
