import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { Paatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getContent = (tili: Paatostili, t: TFunction): YhteenvetoGroupProps[] => {
  const {
    palkkio: {
      veloitatkoPalkkiota,
      palkkioMaara,
      kulukorvausMaara,
      veloitatkoKulukorvauksia,
      kululaskelma,
    },
  } = tili

  const content: YhteenvetoGroupProps[] = [
    {
      heading: t('veloitatkoPalkkiotaTaltaTilikaudelta'),
      rows: [
        veloitatkoPalkkiota !== undefined
          ? veloitatkoPalkkiota
            ? `${t('kylla')}: ${
                palkkioMaara || palkkioMaara === 0
                  ? formatNumberWithComma(palkkioMaara)
                  : ''
              } €`
            : t('eiLuovunPalkkiostaTilikaudella')
          : undefined,
      ],
    },
    {
      heading: t('veloitatkoKulukorvauksiaTaltaTilikaudelta'),
      rows: [
        veloitatkoKulukorvauksia !== undefined
          ? veloitatkoKulukorvauksia
            ? `${t('kylla')}: ${
                kulukorvausMaara || kulukorvausMaara === 0
                  ? formatNumberWithComma(kulukorvausMaara)
                  : ''
              } €`
            : t('eiLuovunkorvauksistaTilikaudella')
          : undefined,
      ],
    },
    {
      heading: t('palkkioJaKululaskelma'),
      rows: [],
      attachments: kululaskelma,
    },
  ]
  return content
}

const Palkkio: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getPaatostili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('palkkio-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('palkkio')}
      </Heading>
      <YhteenvetoGroups groups={getContent(tili, t)} />
    </section>
  )
})

export default Palkkio
