import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import {
  getAddressString,
  getPaivamaaraString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/groups/yhteenveto.util'
import { AsiointiHabitationType } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { Heading } from 'suomifi-ui-components'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

const getAsuinpaikatContent = (
  tili: Paatostili | Omaisuusluettelo,
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const { asuinpaikat } = tili
  return asuinpaikat.map(
    ({
      streetAddress,
      streetAddressExtra,
      postalCode,
      postOffice,
      countryCode,
      habitationType,
      habitationPeriodType,
      beginDate,
      endDate,
    }): YhteenvetoGroupProps => {
      const fullDetails =
        habitationType !== AsiointiHabitationType.HOUSELESS &&
        habitationType !== AsiointiHabitationType.UNKNOWN
      return {
        heading: t(`habitationType_${habitationType}`),
        rows: [
          fullDetails
            ? getAddressString(
                {
                  streetAddress,
                  streetAddressExtra,
                  postalCode,
                  postOffice,
                  countryId: countryCode,
                },
                lang
              )
            : '',
          `${
            fullDetails && habitationPeriodType
              ? t(`habitationPeriodType_${habitationPeriodType}`)
              : ''
          }${getPaivamaaraString(t, beginDate, endDate)}`,
        ],
      }
    }
  )
}

const getHoitoJaPalvelusuunnitelmaContent = (
  tili: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const { hasHoitosuunnitelma, hoitosuunnitelmat } = tili
  const content: YhteenvetoGroupProps[] = [
    {
      heading: t('hoitoJaPalvelusuunnitelmaHeading'),
      rows: [
        hasHoitosuunnitelma !== undefined
          ? hasHoitosuunnitelma
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
      attachments: hoitosuunnitelmat,
    },
  ]
  return content
}

const getAlaikaisenAsuminen = (
  tili: Paatostili | Omaisuusluettelo,
  t: TFunction,
  lang: HolhousAsiointiLanguageCode
): YhteenvetoGroupProps[] => {
  const groups = [
    {
      heading: t('alaikainenAsuinpaikkaOhje'),
      rows: [
        tili.asuuHuoltajanKanssa !== undefined
          ? tili.asuuHuoltajanKanssa
            ? t('kylla')
            : t('ei')
          : undefined,
      ],
    },
  ]

  if (tili.asuuHuoltajanKanssa) {
    groups.push({
      heading: t('kenenKanssaHenkiloAsuu'),
      rows: [tili.huoltajienNimet],
    })
  }

  if (!tili.asuuHuoltajanKanssa) {
    const asuinpaikat = getAsuinpaikatContent(tili, t, lang)
    groups.push(
      ...(asuinpaikat.length
        ? asuinpaikat
        : [{ heading: t('asuinpaikka'), rows: [] }])
    )
  }

  return groups
}

const Asuminen: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    isPrincipalUnderaged,
    lomake: { tili },
  } = getTilintarkastusStore()
  const lang = useAsiointiUserStore().lang
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('asuminen-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('asuminenHeading')}
      </Heading>

      {isPrincipalUnderaged && tili.asiaType === 'OMAISUUSLUETTELO' ? (
        <YhteenvetoGroups groups={getAlaikaisenAsuminen(tili, t, lang)} />
      ) : (
        <>
          <YhteenvetoGroups
            requireRowsOrAttachments={false}
            groups={getAsuinpaikatContent(tili, t, lang)}
          />
          {tili.asiaType === 'OMAISUUSLUETTELO' && (
            <YhteenvetoGroups
              groups={getHoitoJaPalvelusuunnitelmaContent(tili, t)}
            />
          )}
        </>
      )}
    </section>
  )
})

export default Asuminen
