import { Controller, useFormContext } from 'react-hook-form'
import { when } from 'mobx'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { StatusText } from 'suomifi-ui-components'
import React, { ReactElement, useState } from 'react'
import { observer } from 'mobx-react'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'

type FormCustomValidationRuleProps<T> = {
  validate: (t: T) => string | undefined
  value: T
  'data-test-id': string
  children: ReactElement
}

const FormCustomValidationRule = observer(
  <T extends object>({
    validate,
    value,
    'data-test-id': dataTestId,
    children,
  }: FormCustomValidationRuleProps<T>) => {
    const {
      formState: { errors },
      control,
    } = useFormContext()

    // Initialize controller value once and only once --hack
    const [previousValidateResult, setPreviousValidateResult] = useState<
      string | undefined
    >('this-forces-always-to-run-once-to-initialize-the-controller-value')

    return (
      <section
        data-test-id={dataTestId}
        data-test-error-message={(errors[dataTestId]?.message || '') as string}
      >
        <Controller
          control={control}
          name={dataTestId}
          rules={{
            validate,
            value: value,
          }}
          render={({ field: { onChange, name, ref } }) => {
            // Update whenever it produces different error (or validates)!
            // This handles an issue where initial value is not recognized
            when(
              () => {
                const notOld = validate(value)
                return notOld !== previousValidateResult
              },
              () => {
                const validateResult = validate(value)
                setPreviousValidateResult(validateResult)
                onChange(createOnChangeEvent(value, name))
              }
            )
            return (
              <FocusableDiv tabIndex={0} ref={ref}>
                {children}
              </FocusableDiv>
            )
          }}
        />
        {errors[dataTestId] && (
          <StatusText status={'error'} mt={'xxs'} ariaLiveMode={'assertive'}>
            {(errors[dataTestId]?.message || '') as string}
          </StatusText>
        )}
      </section>
    )
  }
)

export default FormCustomValidationRule
