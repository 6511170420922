import { flow, observable, runInAction, toJS } from 'mobx'
import * as draftApi from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/api/draft-api-client'
import { LongIsoDateString } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import {
  Drafts,
  Draft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { Lomake } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

export type DraftStore = Drafts

const draftStore: DraftStore = observable({ drafts: [] })

export function useDraftStore(): DraftStore {
  return draftStore
}

// Override local state by the state retrieved from the server
export const fetchDraftsIntoStore = flow(function* () {
  const { drafts } = yield draftApi.getDrafts()
  draftStore.drafts = observable(drafts)
})

export const createDraftStore = (data: Draft[]): DraftStore => {
  runInAction(() => {
    draftStore.drafts = observable(data)
  })
  return draftStore
}

export const getTiliDraftBySeurantaasiavaatimus = (
  seurantaasiavaatimusId: number
): Draft | undefined => {
  return draftStore.drafts.find(
    ({ sourceId }) => sourceId === seurantaasiavaatimusId.toString()
  )
}

export const saveTiliAsDraft = async (
  lomake: Lomake,
  visitedSteps: string[]
): Promise<void> =>
  flow(function* () {
    if (!isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS)) {
      return
    }
    const tiliDraftToSave = observable({
      sourceId: lomake.tili.seurantaAsiavaatimusId.toString(),
      type: lomake.tili.asiaType,
      data: toJS({ lomake, visitedSteps }), // a non-observable copy of the original to avoid it changing
      updatedAt: new Date().toISOString() as LongIsoDateString, // user specific date to avoid re-renders
    })
    let found = false
    for (const storeDraft of draftStore.drafts) {
      if (storeDraft.sourceId === tiliDraftToSave.sourceId) {
        storeDraft.data = tiliDraftToSave.data
        storeDraft.updatedAt = tiliDraftToSave.updatedAt
        found = true
        break
      }
    }
    if (!found) {
      draftStore.drafts.push(tiliDraftToSave)
    }
    return yield draftApi.saveTiliDraft(tiliDraftToSave)
  })()
