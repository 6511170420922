import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { getOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/omaisuusluettelo.store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block } from 'suomifi-ui-components'
import { action } from 'mobx'
import FormSimpleListInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSimpleListInput'
import React, { FC } from 'react'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'

const OmassaKaytossaOlevaOmaisuusForm: FC = observer(() => {
  const [t] = useTranslation()
  const tili = getOmaisuusluettelo()
  const isTablet = useDeviceContext().tablet

  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkOmaisuusTestId('kaytossa-oleva-container')}>
      <FormRadioButtonGroup
        required
        value={tili.hasKaytossaOlevaOmaisuus?.toString()}
        updateValue={action((value) => {
          tili.hasKaytossaOlevaOmaisuus = value === 'true'
        })}
        data-test-id={mkOmaisuusTestId('omassa-kaytossa-radio')}
        labelText={t('onkoHenkilollaOmaisuutta')}
        groupHintText={t('ohjeOnkoHenkilollaOmaisuutta')}
        radioButtons={[
          { value: 'false', labelText: t('ei') },
          { value: 'true', labelText: t('kylla') },
        ]}
      />

      {tili.hasKaytossaOlevaOmaisuus && (
        <Block mt={verticalMarginToken}>
          <FormSimpleListInput
            dataTestIdPrefix={mkOmaisuusTestId('kaytto-omaisuus')}
            description={t('kaytossaOlevaOmaisuusListInfo')}
            heading={t('kaytossaOlevaOmaisuusListHeading')}
            state={tili.kaytossaOlevaOmaisuus}
          />
        </Block>
      )}
    </section>
  )
})

export default OmassaKaytossaOlevaOmaisuusForm
