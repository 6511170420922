import React from 'react'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Heading,
  IconFeedback,
  IconLaptopContent,
  IconLinkExternal,
  IconMoney,
  IconSwapRounded,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import FeatureNotification from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/FeatureNotification'
import { mkPaamiesFrontPageTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import styled from 'styled-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useTranslation } from 'react-i18next'
import { getLupaFeedbackUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { HelpSection } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/HelpSection'
import { ColoredInlineAlert } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/ColoredInlineAlert'
import OmaisuudenHallinta from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/OmaisuudenHallinta'
import {
  clearValittuPaamies,
  useValittuHenkilo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { useNavigate } from 'react-router-dom'
import { ASIOINTI_MAIN_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-main-routes'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/auto-focus'

const PaamiesFrontPage: React.FC = observer(() => {
  const paamiesValittu = useValittuHenkilo()
  const [t] = useTranslation()
  const navigate = useNavigate()
  const ref = useAutoFocus<HTMLDivElement>()

  return (
    <CenteredWidthLimitedDiv paddingX paddingY>
      <Block mb={'xs'} tabIndex={-1} ref={ref}>
        <Text variant="bold">{t('asioitPuolesta')}:</Text>
      </Block>

      <Separator>
        <Heading
          variant="h1"
          data-test-id={mkPaamiesFrontPageTestId('paamies-heading')}
        >
          {paamiesValittu.lastname}, {paamiesValittu.firstnames}
        </Heading>
        <Button
          data-test-id={mkPaamiesFrontPageTestId('vaihda-paamies')}
          onClick={() => {
            clearValittuPaamies()
            navigate(ASIOINTI_MAIN_ROUTES.JUURI)
          }}
          icon={<IconSwapRounded />}
          variant="secondary"
          aria-label={t('vaihdaHenkiloa')}
        >
          {t('vaihdaHenkiloa')}
        </Button>
      </Separator>
      <Block mt="m" />
      <FeatureNotification />
      <Block mt="s" />
      <TiliSection />
      <Block mt="s" />
      <LupaSection />
      <Block mt="s" />
      <FeedbackSection />
      <Block mt="s" />
      <HelpSection />
    </CenteredWidthLimitedDiv>
  )
})
const TiliSection = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  return (
    <TiliInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
      data-test-id={mkPaamiesFrontPageTestId('tili-korttikehys')}
    >
      <MoneyIcon />
      <Block mt="m" />
      <Heading variant="h3" as="h2">
        {t('tilinTekeminen')}
      </Heading>

      <Block mt="m" />
      <TextBreakSpace>{t('edunvalvontaTehtavasiAlla')}</TextBreakSpace>
      <Block mt="m" />

      <div data-test-id={mkPaamiesFrontPageTestId('logged-in-user')}>
        <OmaisuudenHallinta />
      </div>
    </TiliInlineAlert>
  )
})

const LupaSection: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const navigate = useNavigate()
  return (
    <LupaInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
    >
      <LaptopIcon />
      <Heading variant="h3">{t('overviewLupaInfoHeader')}</Heading>
      <Block mt="s" />
      <TextBreakSpace>{t('luvanHakeminenKirjautunutInfo')}</TextBreakSpace>
      <Block mt="m" />
      <OverviewButton
        data-test-id={mkPaamiesFrontPageTestId('haeLupaa')}
        onClick={() => navigate(`/luvat/hakemus/hae`)}
        fullWidth={false}
        role="link"
        variant="default"
      >
        {t('overviewLupaInfoActionButton')}
      </OverviewButton>
    </LupaInlineAlert>
  )
}
const FeedbackSection: React.FC = observer(() => {
  const [t] = useTranslation()
  const userStore = useAsiointiUserStore()
  const isTablet = useDeviceContext().tablet

  return (
    <FeedbackInlineAlert
      status="neutral"
      ariaLiveMode="off"
      smallScreen={!isTablet}
    >
      <FeedbackIcon />
      <Heading variant="h3" as="h2">
        {t('overviewFeedbackHeader')}
      </Heading>
      <Block mt="m" />
      <Paragraph mb="m">
        <Text>{t('overviewFeedbackDescription')}</Text>
      </Paragraph>

      <OverviewButton
        data-test-id={mkPaamiesFrontPageTestId('annaPalautetta')}
        onClick={() =>
          window.open(getLupaFeedbackUrl(userStore.lang), '_blank')
        }
        fullWidth={false}
        iconRight={<IconLinkExternal />}
        role="link"
        variant="default"
      >
        {t('overviewFeedbackActionButton')}
      </OverviewButton>
    </FeedbackInlineAlert>
  )
})

const MoneyIcon = styled(IconMoney).attrs(() => ({
  highlightColor: suomifiDesignTokens.colors.accentTertiaryDark1,
}))`
  height: 70px;
  width: 70px;
`

const LaptopIcon = styled(IconLaptopContent)`
  height: 70px;
  width: 70px;
`
const FeedbackIcon = styled(IconFeedback).attrs(() => ({
  highlightColor: suomifiDesignTokens.colors.accentSecondary,
}))`
  height: 70px;
  width: 70px;
`

const LupaInlineAlert = ColoredInlineAlert(
  suomifiDesignTokens.colors.accentBase
)

const FeedbackInlineAlert = ColoredInlineAlert(
  suomifiDesignTokens.colors.accentSecondary
)
export const OverviewButton = styled(Button)`
  && {
    ${suomifiDesignTokens.typography.bodySemiBoldSmall}
    border: 1px solid;
    border-radius: 2px;

    @media ${device.tablet} {
      width: fit-content;
    }
  }
`

const TiliInlineAlert = ColoredInlineAlert(
  suomifiDesignTokens.colors.accentTertiary
)

const Separator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextBreakSpace = styled(Text)`
  white-space: pre-line;
`
export default PaamiesFrontPage
