import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkKayttovaratTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { action, runInAction } from 'mobx'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { Paatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'

const KayttovaratForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'

  const tili = getTili()

  return (
    <section data-test-id={mkKayttovaratTestId('kayttovarat-container')}>
      <Heading mb="s" variant="h3">
        {t(translations[tili.asiaType].heading)}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t(translations[tili.asiaType].info)}
      </Paragraph>

      {tili.asiaType !== 'OMAISUUSLUETTELO' && (
        <Block mb={verticalMarginToken}>
          <FormRadioButtonGroup
            required
            value={tili.isAnnettuKayttovaroja?.toString()}
            data-test-id={mkKayttovaratTestId('onko-radio')}
            labelText={t('onkoHenkilolleAnnettuKayttovarojaTallaTilikaudella')}
            updateValue={action(
              (value) => (tili.isAnnettuKayttovaroja = value === 'true')
            )}
            radioButtons={[
              {
                value: 'false',
                labelText: t('ei'),
              },
              {
                value: 'true',
                labelText: t('kylla'),
              },
            ]}
          />
        </Block>
      )}

      <FormTextArea
        data-test-id={mkKayttovaratTestId('kaytanto-input')}
        labelText={t(
          translations[tili.asiaType].kaytantoLabel[
            (tili as Paatostili).isAnnettuKayttovaroja || false ? 'kylla' : 'ei'
          ]
        )}
        hintText={t(
          translations[tili.asiaType].kaytantoHint[
            (tili as Paatostili).isAnnettuKayttovaroja || false ? 'kylla' : 'ei'
          ]
        )}
        maxLength={1000}
        required
        value={tili.kayttovaraKaytanto}
        validate={commonXssValidator(t)}
        updateValue={(value) => {
          runInAction(() => {
            tili.kayttovaraKaytanto = value
          })
        }}
      />
    </section>
  )
})

export default KayttovaratForm

const translations = {
  OMAISUUSLUETTELO: {
    heading: 'kayttovarojenTarveJaKaytannot',
    info: 'ohjeKayttovarojenTarveJaKaytannot',
    kaytantoLabel: {
      kylla: 'mitenAnnatKayttovarojaHenkilonOmaanKayttoon',
      ei: 'mitenAnnatKayttovarojaHenkilonOmaanKayttoon',
    },
    kaytantoHint: {
      kylla: 'ohjeJosEtAnnaKayttovaroja',
      ei: 'ohjeJosEtAnnaKayttovaroja',
    },
  },
  PAATOSTILI: {
    heading: 'kayttovarat',
    info: 'ohjeIlmoitaMitaKayttovarojaHenkilolle',
    kaytantoLabel: {
      kylla: 'mitenKayttovarojaOnHenkilolleAnnettu',
      ei: 'miksiEiKayttovaroja',
    },
    kaytantoHint: {
      kylla: 'ohjeKuvaileKayttovarojenKaytontoja',
      ei: '',
    },
  },
}
