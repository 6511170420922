import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const getPankkitilit = (
  tili: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  return (tili as Omaisuusluettelo).paamiehenOmaisuus.pankkitilit.map((p) => ({
    heading: p.pankinNimi,
    rows: [
      p.iban ? `${t('tilinumero')}: ${p.iban}` : undefined,
      p.kayttovaratili !== undefined
        ? `${t('tamaOnHenkilonKayttotili')}: ${
            p.kayttovaratili ? t('kylla') : t('ei')
          }`
        : undefined,
      p.isKayttoOikeuksia !== undefined
        ? `${t('onkoTilinKayttooikeuksiaMuilla')}: ${
            p.isKayttoOikeuksia ? t('kylla') : t('ei')
          }. ${p.isKayttoOikeuksia ? p.kayttooikeudet : ''}`
        : undefined,
    ],
    attachments: p.sopimukset,
  }))
}

const OmassaKaytossaPankkitilit: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTili()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('pankkitilitHeading')}
      </Heading>
      <YhteenvetoGroups groups={getPankkitilit(tili, t)} />
    </>
  )
})

export default OmassaKaytossaPankkitilit
