import { action, flow, observable } from 'mobx'
import { TilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store.type'
import { AsiointiLocale } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { CancellablePromise } from 'mobx/dist/api/flow'
import {
  AsiointiType,
  FrontOmaisuusTilirivit,
  FrontTilirivit,
  Lomake,
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiointiPrivatePersonRole } from 'tilintarkastus-common/src/vtj/tili-account-enums'
import { COUNTRY_CODE_FINLAND } from 'holhous-common/src/vtj/country/country.util'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { AsiointiUser } from 'edunvalvonta-asiointi/src/vtj/asiointi/authentication/holhous-asiointi-user.type'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { TIEDOKSIANTO_PERSON_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tiedoksianto.store'
import { isPaatostili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { DeepPartial } from 'edunvalvonta-asiointi/src/vtj/asiointi/types'
import { DraftData } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { saveTiliAsDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { OMAISUUSLUETTELO_ROUTES } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'

let store: TilintarkastusStore | undefined

export const initTilintarkastusStore = (
  user: AsiointiUser,
  evtvSeurantaAsiavaatimus: AsiointiSeurantaAsiavaatimus,
  draftData: DraftData | undefined,
  lang: HolhousAsiointiLanguageCode
): TilintarkastusStore => {
  if (
    draftData &&
    draftData.lomake?.tili?.seurantaAsiavaatimusId !==
      evtvSeurantaAsiavaatimus.seurantaAsiavaatimusId
  ) {
    throw new Error(
      `Mismatch between EVTV ${evtvSeurantaAsiavaatimus.seurantaAsiavaatimusId} and saved draft data vaatimus ${draftData.lomake?.tili?.seurantaAsiavaatimusId}`
    )
  }

  const tili =
    evtvSeurantaAsiavaatimus.asiaType === 'PAATOSTILI'
      ? createEmptyPaatostili(evtvSeurantaAsiavaatimus)
      : createEmptyOmaisuusluettelo(evtvSeurantaAsiavaatimus)
  const lomake = createLomake(user, tili, draftData?.lomake, lang)

  const excludeSteps = new Set<string>(
    evtvSeurantaAsiavaatimus.asiaType === 'OMAISUUSLUETTELO' &&
    evtvSeurantaAsiavaatimus.tilivelvollisuusType !==
      TilivelvollisuusType.OMAISUUSLUETTELO_YHDESSA
      ? [OMAISUUSLUETTELO_ROUTES.VALTAKIRJA]
      : []
  )

  store = observable({
    lomake,
    visitedSteps: new Set<string>(draftData?.visitedSteps || []),
    excludeSteps,
    submitStatus: 'none',
    submitError: undefined,
    accountingPeriodStartDate:
      evtvSeurantaAsiavaatimus.accountingPeriodStartDate,
    isPrincipalUnderaged: evtvSeurantaAsiavaatimus.isPrincipalUnderaged,
  } as TilintarkastusStore)
  return store
}

// For testing purposes only
export const initTilintarkastusStoreWithData = (
  data: TilintarkastusStore
): TilintarkastusStore => {
  store = observable(data)
  return store
}

export const getTilintarkastusStore = (): TilintarkastusStore => {
  if (store) {
    return store
  } else {
    throw new Error('TilintarkastusStore not initialized')
  }
}

export const isTilintarkastusStoreInitialized = (
  seurantaAsiavaatimusId: number
): boolean => {
  if (store && store.lomake) {
    return store.lomake.tili.seurantaAsiavaatimusId === seurantaAsiavaatimusId
  }
  return false
}

export const runTilintarkastusStoreFlow = <T>(
  fn: (store: TilintarkastusStore) => Generator<any, T, any> // eslint-disable-line @typescript-eslint/no-explicit-any
): CancellablePromise<T> => {
  const store = getTilintarkastusStore()
  return flow<T, [TilintarkastusStore]>(fn)(store)
}

export const runTilintarkastusStoreAction = <T>(
  fn: (store: TilintarkastusStore) => T
): T => {
  const store = getTilintarkastusStore()
  return action(fn)(store)
}

export const saveDraft = async (): Promise<void> => {
  const { submitStatus, lomake, visitedSteps } = getTilintarkastusStore()
  if (submitStatus === 'submitted') {
    return
  }
  await saveTiliAsDraft(lomake, Array.from(visitedSteps))
}

const createEmptyPaatostili = (
  evtvVaatimus: AsiointiSeurantaAsiavaatimus
): Paatostili => {
  return {
    isMuutMerkittavatToimet: undefined,
    muutMerkittavatToimet: undefined,
    isAnnettuKayttovaroja: undefined,
    kayttovaraKaytanto: undefined,
    asuinpaikat: [],
    saannollisetTulot: [],
    verotustiedot: [],
    sopimukset: [],
    vakuutukset: [],
    paamiehenOmaisuus: {
      pankkitilit: [],
    },
    elaketulotElinkustannuksiin: {
      elinkustannuksiinKaytettavatElaketulot: undefined,
      enKaytaElakettaElinkustannuksiin: false,
    },
    tilirivit: {
      varat: {
        talletukset: [],
        saatavat: [],
        arvopaperit: [],
        huoneistot: [],
        irtaimistot: [],
        kiinteistot: [],
        muutVarat: [],
        osuudetHenkiloYhtiossaJaYrityksissa: [],
        osuudetKuolinpesissa: [],
      },
      velat: {
        pankkilainat: [],
        takaukset: [],
        muutVelat: [],
      },
      tulot: {
        elakkeet: [],
        palkat: [],
        etuudet: [],
        tyottomyysturva: [],
        vuokratulot: [],
        osinkotulot: [],
        korkotulot: [],
        myyntitulot: [],
        muutTulot: [],
      },
      menot: {
        kayttovarat: [],
        elinkustannukset: [],
        palvelumaksut: [],
        vuokratVastikkeet: [],
        elatusavut: [],
        pankinPerimatMaksut: [],
        verot: [],
        tyonantajakulut: [],
        velanhoitomenot: [],
        edunvalvonnankulut: [],
        muutMenot: [],
      },
    },
    palkkio: {
      veloitatkoKulukorvauksia: undefined,
      veloitatkoPalkkiota: undefined,
      kulukorvausMaara: 0,
      kululaskelma: [],
      palkkioMaara: 0,
    },
    seurantaAsiavaatimusId: evtvVaatimus.seurantaAsiavaatimusId,
    asiaType: AsiointiType.PAATOSTILI,
    isHallintaoikeuksia: undefined,
    mitenHallintaoikeuksistaOnSovittu: undefined,
    hallintaoikeusSopimukset: [],
    edunvalvojanValtakirja: [],
    hoitosuunnitelmat: [],
  }
}

const createEmptyOmaisuusluettelo = (
  evtvVaatimus: AsiointiSeurantaAsiavaatimus
): Omaisuusluettelo => {
  return {
    asuinpaikat: [],
    edunvalvojanValtakirja: [],
    hallintaoikeusSopimukset: [],
    hoitosuunnitelmat: [],
    kayttovaraKaytanto: undefined,
    saannollisetTulot: [],
    sopimukset: [],
    vakuutukset: [],
    verotustiedot: [],
    isMuutMerkittavatToimet: undefined,
    tilirivit: {
      varat: {
        talletukset: [],
        saatavat: [],
        arvopaperit: [],
        huoneistot: [],
        irtaimistot: [],
        kiinteistot: [],
        muutVarat: [],
        osuudetHenkiloYhtiossaJaYrityksissa: [],
        osuudetKuolinpesissa: [],
      },
      velat: {
        pankkilainat: [],
        takaukset: [],
        muutVelat: [],
      },
    },
    asiaType: AsiointiType.OMAISUUSLUETTELO,
    kaytossaOlevaOmaisuus: [{ id: crypto.randomUUID(), value: '' }],
    elaketulotElinkustannuksiin: {
      elinkustannuksiinKaytettavatElaketulot: undefined,
      enKaytaElakettaElinkustannuksiin: false,
    },
    paamiehenOmaisuus: {
      muutVarat: undefined,
      isMuutVarat: undefined,
      omaisuusOmassaKaytossa: [],
      pankkitilit: [],
    },
    testamentti: { laadittu: undefined, rajoittaviaEhtoja: undefined },
    isHallintaoikeuksia: undefined,
    seurantaAsiavaatimusId: evtvVaatimus.seurantaAsiavaatimusId,
  }
}

export const createLomake = (
  user: AsiointiUser,
  tili: Paatostili | Omaisuusluettelo,
  previouslySavedLomake: DraftData['lomake'] | undefined,
  lang: HolhousAsiointiLanguageCode
): Lomake => {
  const [previousTiedoksiantoPerson] =
    previouslySavedLomake?.otherPersons?.privatePersons?.filter(
      (p) => p?.id === TIEDOKSIANTO_PERSON_ID
    ) || []

  return {
    locale: AsiointiLocale.fi, // TODO language pitäis olla dynaaminen? Aseta vasta ennen lähetystä?
    otherPersons: {
      privatePersons:
        tili.asiaType !== 'PAATOSTILI'
          ? []
          : [
              {
                id: TIEDOKSIANTO_PERSON_ID,
                role: AsiointiPrivatePersonRole.YKSITYISHENKILO,
                email: previousTiedoksiantoPerson?.email || '',
                firstnames: previousTiedoksiantoPerson?.firstnames || '',
                lastname: previousTiedoksiantoPerson?.lastname || '',
                phone: previousTiedoksiantoPerson?.phone || '',
                address: {
                  streetAddress:
                    previousTiedoksiantoPerson?.address?.streetAddress || '',
                  postOffice:
                    previousTiedoksiantoPerson?.address?.postOffice || '',
                  postalCode:
                    previousTiedoksiantoPerson?.address?.postalCode || '',
                  countryId:
                    previousTiedoksiantoPerson?.address?.countryId ||
                    COUNTRY_CODE_FINLAND,
                },
                isAssetsRecipient: true,
              },
            ],
      asiointiCaretakers: [],
      businesses: [],
    },
    caretaker: {
      firstnames:
        previouslySavedLomake?.caretaker?.firstnames ?? user.firstnames,
      lastname: previouslySavedLomake?.caretaker?.lastname ?? user.lastname,
      email: previouslySavedLomake?.caretaker?.email || user.email || '', // käyttäjä saa poistaa userin arvon
      phone: previouslySavedLomake?.caretaker?.phone || '',
      address: {
        streetAddress:
          previouslySavedLomake?.caretaker?.address?.streetAddress ??
          user.streetAddress?.[lang] ??
          '',
        postOffice:
          previouslySavedLomake?.caretaker?.address?.postOffice ??
          user.postOffice?.[lang] ??
          '',
        postalCode:
          previouslySavedLomake?.caretaker?.address?.postalCode ??
          user.postalCode ??
          '',
        countryId:
          previouslySavedLomake?.caretaker?.address?.countryId ??
          user.countryCode ??
          COUNTRY_CODE_FINLAND,
      },
    },
    tili: previouslySavedLomake?.tili
      ? mergeEmptyAndPreviousLomake(tili, previouslySavedLomake.tili)
      : tili,
  }
}

const mergeEmptyAndPreviousLomake = <T extends Paatostili | Omaisuusluettelo>(
  tyhjaTili: T,
  draftTili: DeepPartial<T>
): T => {
  const { tilirivit, ...safePreviousValues } = draftTili

  // Yhteiset säännöt -->
  const sharedFields = {
    // turvalliset arvot pohjalle
    ...tyhjaTili,
    ...safePreviousValues,

    // Näitä ei saa ylikirjoittaa
    seurantaAsiavaatimusId: tyhjaTili.seurantaAsiavaatimusId,
    asiaType: tyhjaTili.asiaType,

    // Kun kaikki draft-tyypin arvot ovat partial,
    //  niin varmistetaan että object keys pysyvät ennallaan
    // esim jos draftilla on {key: 'kissa'}
    // ja tyhjässä tilissä on {key: undefined', key2: 'koira'}
    // niin ei voi korvata suoraan tyhjään draftilla, vaan pitää olla merge, että lopputulos:
    // {key: 'kissa', key2: 'koira'}
    tilirivit: tilirivit
      ? mergeTilirivit(tyhjaTili.tilirivit, tilirivit, tyhjaTili.asiaType)
      : tyhjaTili.tilirivit,

    ...(tyhjaTili.elaketulotElinkustannuksiin && {
      elaketulotElinkustannuksiin: {
        ...tyhjaTili.elaketulotElinkustannuksiin,
        ...draftTili.elaketulotElinkustannuksiin,
      },
    }),
  }

  // Tilityyppikohtaiset säännöt -->
  if (isPaatostili(tyhjaTili)) {
    const { palkkio } = draftTili as Paatostili
    return {
      ...sharedFields,
      palkkio: palkkio ? { ...tyhjaTili, ...palkkio } : tyhjaTili.palkkio,
    }
  } else {
    const {
      testamentti: draftTestamentti,
      paamiehenOmaisuus: draftPaamiehenOmaisuus,
    } = draftTili as Omaisuusluettelo
    return {
      ...sharedFields,
      ...(tyhjaTili.testamentti && {
        testamentti: {
          ...tyhjaTili.testamentti,
          ...draftTestamentti,
        },
      }),
      ...(tyhjaTili.paamiehenOmaisuus && {
        paamiehenOmaisuus: {
          ...tyhjaTili.paamiehenOmaisuus,
          ...draftPaamiehenOmaisuus,
        },
      }),
    }
  }
}

const mergeTilirivit = (
  emptyTilirivit: FrontTilirivit | FrontOmaisuusTilirivit,
  draftTilirivit: DeepPartial<FrontTilirivit | FrontOmaisuusTilirivit>,
  asiaType: AsiointiType
): FrontTilirivit | FrontOmaisuusTilirivit => {
  const commonTilirivit = {
    varat: {
      ...emptyTilirivit.varat,
      ...draftTilirivit.varat,
    },
    velat: {
      ...emptyTilirivit.velat,
      ...draftTilirivit.velat,
    },
  } as FrontOmaisuusTilirivit
  if (asiaType === 'PAATOSTILI') {
    return {
      ...commonTilirivit,
      menot: {
        ...emptyTilirivit.menot,
        ...draftTilirivit.menot,
      },
      tulot: {
        ...emptyTilirivit.tulot,
        ...draftTilirivit.tulot,
      },
    } as FrontTilirivit
  } else {
    return commonTilirivit
  }
}
