import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import {
  IsoDatetimeToUiDate,
  valitseVaatimus,
  useVaatimukset,
  useValittuHenkilo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import {
  Block,
  Heading,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { mkOmaisuudenHallintaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import React, { FC, PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import {
  OMAISUUSLUETTELO_ROUTES,
  PAATOSTILI_ROUTES,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { OverviewButton } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiesFrontPage'
import { HolhousAsiaType } from 'tilintarkastus-common/src/vtj/types/holhous-asia/holhous-asia-enums'
import {
  AsiointiSeurantaAsiavaatimus,
  LongIsoDateString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { getTiliDraftBySeurantaasiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import TiliStatusChip from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/TiliStatusChip'
import { endOfDay, isAfter, parseISO } from 'date-fns'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { AsiaType } from 'asia-common/src/vtj/koodisto/asia-enums'

const OmaisuudenHallinta = observer(() => {
  const [t] = useTranslation()
  const evtvVaatimukset = useVaatimukset()
  const valittuPaamies = useValittuHenkilo()
  const henkilonVaatimukset = evtvVaatimukset?.filter(
    ({ personId }) => personId === valittuPaamies.personId
  )

  if (!henkilonVaatimukset?.length) {
    return (
      <>
        <StyledRuler />
        <Block mt="m" />
        <Paragraph
          data-test-id={mkOmaisuudenHallintaTestId('ei-velvollisuutta')}
        >
          <ItalicText>{t('evEiTiliVelvollisuutta')}</ItalicText>
        </Paragraph>
      </>
    )
  }

  const isTiliLate = (vaatimus: AsiointiSeurantaAsiavaatimus): boolean => {
    const now = new Date()
    const todayEOD = endOfDay(now)
    const dueDateEOD = endOfDay(parseISO(vaatimus.dueDate))
    return isAfter(todayEOD, dueDateEOD)
  }

  return (
    <TilitList
      data-test-id={mkOmaisuudenHallintaTestId('omaisuudenhallinta-container')}
    >
      {henkilonVaatimukset.map((evtvVaatimus) => {
        const translations =
          omaisuudenHallintaTranslationsByType[evtvVaatimus.asiaType]
        const tiliDraft = getTiliDraftBySeurantaasiavaatimus(
          evtvVaatimus.seurantaAsiavaatimusId
        )
        return (
          <PaamiehenTili
            data-test-id={mkOmaisuudenHallintaTestId(
              'seuranta-asia',
              evtvVaatimus.seurantaAsiavaatimusId
            )}
            key={evtvVaatimus.seurantaAsiavaatimusId}
          >
            <Heading
              variant="h3"
              color="highlightBase"
              data-test-id={mkOmaisuudenHallintaTestId(
                'tilityyppi',
                evtvVaatimus.seurantaAsiavaatimusId
              )}
            >
              {t(translations.tilitype)}
            </Heading>
            <Row>
              <TehtavanAjanjakso
                asiaType={evtvVaatimus.asiaType}
                alkupvm={evtvVaatimus.accountingPeriodStartDate}
                loppupvm={evtvVaatimus.accountingPeriodEndDate}
              />
              <TiliStatusChip evtvVaatimus={evtvVaatimus} />
            </Row>
            <Block mt="s" />
            {evtvVaatimus.sentDate ? (
              <Lahetyspaiva
                sentDate={evtvVaatimus.sentDate}
                text={t(translations.lahetetty)}
              />
            ) : (
              <>
                {isTiliLate(evtvVaatimus) ? (
                  <InlineAlert
                    status="warning"
                    data-test-id={mkOmaisuudenHallintaTestId(
                      'myohassa',
                      evtvVaatimus.seurantaAsiavaatimusId
                    )}
                  >
                    {t(
                      evtvVaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO
                        ? 'omaisuusluetteloMyohassaVaroitus'
                        : 'tiliMyohassaVaroitus',
                      {
                        asiaType: t(evtvVaatimus.asiaType.toLowerCase()),
                        maarapvm: IsoDatetimeToUiDate(evtvVaatimus.dueDate),
                      }
                    )}
                  </InlineAlert>
                ) : (
                  <Paragraph>
                    {t(translations.lahetaViimeistaan)}{' '}
                    {IsoDatetimeToUiDate(
                      evtvVaatimus.sentDate ?? evtvVaatimus.dueDate
                    )}
                  </Paragraph>
                )}
              </>
            )}
            <Block mt="m" />
            {!evtvVaatimus.sentDate && (
              <NavigateToTilintarkastusButton tili={evtvVaatimus}>
                {tiliDraft ? t('jatkaTayttamista') : t('aloitaTayttaminen')}
              </NavigateToTilintarkastusButton>
            )}
          </PaamiehenTili>
        )
      })}
    </TilitList>
  )
})

interface TehtavanAjanjaksoProps {
  asiaType: AsiointiSeurantaAsiavaatimus['asiaType']
  alkupvm: LongIsoDateString
  loppupvm?: LongIsoDateString
}
const TehtavanAjanjakso: FC<TehtavanAjanjaksoProps> = ({
  asiaType,
  alkupvm,
  loppupvm,
}) => {
  const [t] = useTranslation()
  const tehtavanAlkamispaiva = `${t(
    'tehtavanAlkamispaiva'
  )}: ${IsoDatetimeToUiDate(alkupvm)}`
  const tilikausiPvm = `${t('tilikausi')}: ${IsoDatetimeToUiDate(
    alkupvm
  )} - ${IsoDatetimeToUiDate(loppupvm)}`

  return (
    <Text smallScreen variant="bold">
      {asiaType === HolhousAsiaType.OMAISUUSLUETTELO
        ? tehtavanAlkamispaiva
        : tilikausiPvm}
    </Text>
  )
}
interface LahetyspaivaProps {
  text: string
  sentDate: LongIsoDateString
}
const Lahetyspaiva: FC<LahetyspaivaProps> = ({ text, sentDate }) => {
  const formattedPvm = IsoDatetimeToUiDate(sentDate)
  return (
    <Paragraph>
      {text} {formattedPvm}
    </Paragraph>
  )
}

const NavigateToTilintarkastusButton: FC<
  PropsWithChildren & {
    tili: AsiointiSeurantaAsiavaatimus
  }
> = ({ children, tili: { asiaType, seurantaAsiavaatimusId } }) => {
  const navigate = useNavigate()
  return (
    <OverviewButton
      data-test-id={mkOmaisuudenHallintaTestId(
        'tee-tili-nappi',
        seurantaAsiavaatimusId
      )}
      onClick={() => {
        valitseVaatimus(seurantaAsiavaatimusId)
        if (asiaType === AsiaType.OMAISUUSLUETTELO) {
          navigate(OMAISUUSLUETTELO_ROUTES.TEE_OMAISUUSLUETTELO)
        } else if (asiaType === AsiaType.PAATOSTILI) {
          navigate(PAATOSTILI_ROUTES.TEE_PAATOSTILI)
        } else if (asiaType === AsiaType.VUOSITILI) {
          throw Error('TODO vuositili')
        } else {
          throw Error(`Unknown AsiaType ${asiaType}`)
        }
      }}
      fullWidth={false}
      role="link"
      variant="default"
    >
      {children}
    </OverviewButton>
  )
}

const PaamiehenTili = styled.div`
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  padding: ${suomifiDesignTokens.spacing.m};
`

const StyledRuler = styled.hr`
  color: ${suomifiDesignTokens.colors.depthLight1};
  opacity: 50%;
  height: 2px;
`

const TilitList = styled.div`
  gap: ${suomifiDesignTokens.spacing.m};
  display: flex;
  flex-direction: column;
`

const ItalicText = styled(Text)`
  font-style: italic;
`
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${suomifiDesignTokens.spacing.xxs};
`

export default OmaisuudenHallinta

const omaisuudenHallintaTranslationsByType = {
  OMAISUUSLUETTELO: {
    tilitype: 'omaisuusluettelo',
    lahetetty: 'omaisuusluetteloOnLahetetty',
    lahetaViimeistaan: 'lahetaOmaisuusluetteloViimeistaan',
  },
  PAATOSTILI: {
    tilitype: 'paatostili',
    lahetetty: 'paatostiliOnLahetetty',
    lahetaViimeistaan: 'lahetaPaatostiliViimeistaan',
  },
  VUOSITILI: {
    tilitype: 'vuositili',
    lahetetty: '', // TODO
    lahetaViimeistaan: '', // TODO
  },
}
