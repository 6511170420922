import { StaticChip, suomifiDesignTokens } from 'suomifi-ui-components'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { IsoDatetimeToUiDatetime } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import React from 'react'
import { getTiliDraftBySeurantaasiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { useTranslation } from 'react-i18next'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

type TiliStatus = 'not-started' | 'sent'

const TiliStatusChip: React.FC<{
  evtvVaatimus: Pick<
    AsiointiSeurantaAsiavaatimus,
    'seurantaAsiavaatimusId' | 'sentDate'
  >
  hideForStatuses?: TiliStatus[]
}> = observer(({ evtvVaatimus, hideForStatuses = [] }) => {
  const [t] = useTranslation()
  const tiliDraft = getTiliDraftBySeurantaasiavaatimus(
    evtvVaatimus.seurantaAsiavaatimusId
  )
  return (
    <section data-test-id={mkTestId('tili-status-chip')}>
      {evtvVaatimus.sentDate && !hideForStatuses.includes('sent') && (
        <ChipForStatusSent>
          {t('tiliStatus_LAHETETTY')}&nbsp;{'✔'}
        </ChipForStatusSent>
      )}
      {!evtvVaatimus.sentDate && tiliDraft && (
        <ChipForStatusSaved>
          {t('tiliStatus_TALLENNETTU')}&nbsp;
          {IsoDatetimeToUiDatetime(tiliDraft.updatedAt)}
        </ChipForStatusSaved>
      )}
      {!evtvVaatimus.sentDate &&
        !hideForStatuses.includes('not-started') &&
        !tiliDraft && (
          <ChipForStatusNotStarted>
            {t('tiliStatus_ALOITTAMATTA')}
          </ChipForStatusNotStarted>
        )}
    </section>
  )
})

export default TiliStatusChip

const ChipForStatusNotStarted = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background-color: ${suomifiDesignTokens.colors.warningLight1};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

const ChipForStatusSent = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.successSecondary};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

const ChipForStatusSaved = styled(StaticChip)`
  &&& {
    margin-left: ${suomifiDesignTokens.spacing.xxs};
    background: ${suomifiDesignTokens.colors.infoLight1};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`
