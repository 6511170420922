import React from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  Omaisuusluettelo,
  Paatostili,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/types/lomake.type'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Heading } from 'suomifi-ui-components'
import YhteenvetoGroups, {
  YhteenvetoGroupProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const getToimintakykyContent = (
  account: Paatostili | Omaisuusluettelo,
  t: TFunction
): YhteenvetoGroupProps[] => {
  const { toimintakyky } = account
  return [
    {
      heading: t('toimintakykyInputLabel'),
      rows: [toimintakyky],
    },
  ]
}

const Toimintakyky: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  return (
    <>
      <Heading variant="h3" mb={verticalMarginToken}>
        {t('toimintakyky')}
      </Heading>
      <YhteenvetoGroups groups={getToimintakykyContent(tili, t)} />
    </>
  )
})

export default Toimintakyky
