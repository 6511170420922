import _ from 'lodash'
import keyMirror from 'keymirror'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'

export const AsiaVersion = keyMirror({
  V0: null,
  V1: null,
  V2: null,
})
export type AsiaVersionId = (typeof AsiaVersion)[keyof typeof AsiaVersion]

export const AsiaLanguage = keyMirror({
  FIN: null,
  SWE: null,
})
export type AsiaLanguageId = (typeof AsiaLanguage)[keyof typeof AsiaLanguage]
export const allAsiaLanguageIds: AsiaLanguageId[] = _.keys(AsiaLanguage) as AsiaLanguageId[]

export const asiaLanguageToLanguageCode = (
  asiaLanguage?: AsiaLanguageId | null,
  defaultVal = LanguageCode.FI
): LanguageCode => {
  switch (asiaLanguage) {
    case AsiaLanguage.SWE:
      return LanguageCode.SV
    case AsiaLanguage.FIN:
      return LanguageCode.FI
    default:
      return defaultVal
  }
}

export const SourceTable = keyMirror({
  ASIA: null,
  TOIMENPIDE: null,
  ASIAHUOMIO: null,
  ASIARYHMA: null,
  ASIAKIRJA: null,
  APOSTILLE: null,
  ASIAHENKILO: null,
  ASIAKIRJATARKASTUS: null,
})
export type SourceTableId = (typeof SourceTable)[keyof typeof SourceTable]

export const AsiaSource = keyMirror({
  ASIA_UI: null,
  ASIA_BACKEND: null,
  TODISTUKSET: null,
  LUVAT: null,
  TILINTARKASTUS: null,
  NIMENMUUTOS: null,
  TESTI: null,
  ADMIN: null,
  VANHEMMUUDEN_VAHVISTAMINEN: null,
  VAESTOTIETOPALVELUT: null,
  TOIVO: null,
  HOLHOUS_BACKEND: null,
  ELAMANTAPAHTUMAT_BACKEND: null,
})
export type AsiaSourceId = (typeof AsiaSource)[keyof typeof AsiaSource]

export const AsiaServiceArea = keyMirror({
  NIMIASIAT: null,
  LUVAT: null,
  TODISTUKSET: null,
  TILINTARKASTUS: null,
  VANHEMMUUDEN_VAHVISTAMINEN: null,
  ULKOMAALAISEN_REKISTEROINTI: null,
  EDUNVALVONTAVALTUUTUS_JA_EDUNVALVONNAN_ALKAMINEN: null,
  ELAMANTAPAHTUMIEN_REKISTEROINTI: null,
  MUUTOT: null,
})
export type AsiaServiceAreaId = (typeof AsiaServiceArea)[keyof typeof AsiaServiceArea]
export const allAsiaServiceAreaIds: AsiaServiceAreaId[] = _.keys(AsiaServiceArea) as AsiaServiceAreaId[]

export const serviceAreaToAsiaSource: Record<AsiaServiceAreaId, AsiaSourceId> = {
  [AsiaServiceArea.LUVAT]: AsiaSource.LUVAT,
  [AsiaServiceArea.NIMIASIAT]: AsiaSource.NIMENMUUTOS,
  [AsiaServiceArea.TILINTARKASTUS]: AsiaSource.TILINTARKASTUS,
  [AsiaServiceArea.TODISTUKSET]: AsiaSource.TODISTUKSET,
  [AsiaServiceArea.VANHEMMUUDEN_VAHVISTAMINEN]: AsiaSource.VANHEMMUUDEN_VAHVISTAMINEN,
  [AsiaServiceArea.ULKOMAALAISEN_REKISTEROINTI]: AsiaSource.VAESTOTIETOPALVELUT,
  [AsiaServiceArea.EDUNVALVONTAVALTUUTUS_JA_EDUNVALVONNAN_ALKAMINEN]: AsiaSource.HOLHOUS_BACKEND,
  [AsiaServiceArea.ELAMANTAPAHTUMIEN_REKISTEROINTI]: AsiaSource.ELAMANTAPAHTUMAT_BACKEND,
  [AsiaServiceArea.MUUTOT]: AsiaSource.VAESTOTIETOPALVELUT,
}

export const AsiaType = keyMirror({
  ETUNIMENMUUTOS: null,
  SUKUNIMENMUUTOS_PALUU_AIEMPAAN: null,
  SUKUNIMIYHDISTELMAN_MUUTOS: null,
  SUKUNIMENMUUTOS_LUOKITTELEMATON: null,
  ASUINPAIKKATODISTUS: null,
  AVIOLIITTOTODISTUS: null,
  ELOSSAOLOTODISTUS: null,
  SIVIILISAATYTODISTUS: null,
  SYNTYMATODISTUS: null,
  PERINNONJAKO: null,
  MYYNTILUPA: null,
  MUU_LUPA: null,
  VUOSITILI: null,
  PAATOSTILI: null,
  ENSITILI: null,
  TUNNUSTETTU_ENNEN_SYNTYMAA: null,
  TUNNUSTETTU_SYNTYMAN_JALKEEN: null,
  VANHEMMUUDEN_KUMOAMINEN: null,
  OMAISUUSLUETTELO: null,
  KOTIKUNNAN_REKISTEROINTI: null,
  PERHEHAKEMUS: null,
  EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN: null,
  EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMINEN: null,
  SIVIILISAADYN_REKISTEROINTI_KOTIMAINEN: null,
  SIVIILISAADYN_REKISTEROINTI_ULKOMAINEN: null,
  ENSIREKISTEROINTI: null,
  SELVITYSTEHTAVA: null,
  KUOLEMAN_REKISTEROINTI_KOTIMAINEN: null,
  KUOLEMAN_REKISTEROINTI_ULKOMAINEN: null,
  TODISTUS_ASUMISTIEDOISTA: null,
  TODISTUS_HENKILO_JA_PERHESUHDETIEDOISTA: null,
  TODISTUS_KUOLEMASTA: null,
  EDUNVALVOJAN_MAARAAMINEN_ILMOITUKSELLA: null,
  KOTIMAAN_MUUTTO: null,
  LAPSEN_REKISTEROINTI_KOTIMAINEN: null,
  LAPSEN_REKISTEROINTI_ULKOMAINEN: null,
  KANSALAISUUS_JA_ULKOMAATIEDOT: null,
  AVIOLIITON_ESTEIDEN_TUTKINTA: null,
})
export type AsiaTypeId = (typeof AsiaType)[keyof typeof AsiaType]
export const allAsiaTypeIds: AsiaTypeId[] = _.keys(AsiaType) as AsiaTypeId[]

export const ServiceAreaAsiaTypes: Record<AsiaServiceAreaId, AsiaTypeId[]> = {
  [AsiaServiceArea.NIMIASIAT]: [
    AsiaType.ETUNIMENMUUTOS,
    AsiaType.SUKUNIMENMUUTOS_PALUU_AIEMPAAN,
    AsiaType.SUKUNIMIYHDISTELMAN_MUUTOS,
    AsiaType.SUKUNIMENMUUTOS_LUOKITTELEMATON,
    AsiaType.PERHEHAKEMUS,
  ],
  [AsiaServiceArea.TODISTUKSET]: [
    AsiaType.ASUINPAIKKATODISTUS,
    AsiaType.AVIOLIITON_ESTEIDEN_TUTKINTA,
    AsiaType.AVIOLIITTOTODISTUS,
    AsiaType.ELOSSAOLOTODISTUS,
    AsiaType.SIVIILISAATYTODISTUS,
    AsiaType.SYNTYMATODISTUS,
    AsiaType.TODISTUS_ASUMISTIEDOISTA,
    AsiaType.TODISTUS_HENKILO_JA_PERHESUHDETIEDOISTA,
    AsiaType.TODISTUS_KUOLEMASTA,
  ],
  [AsiaServiceArea.LUVAT]: [AsiaType.PERINNONJAKO, AsiaType.MYYNTILUPA, AsiaType.MUU_LUPA],
  [AsiaServiceArea.TILINTARKASTUS]: [
    AsiaType.VUOSITILI,
    AsiaType.PAATOSTILI,
    AsiaType.ENSITILI,
    AsiaType.OMAISUUSLUETTELO,
  ],
  [AsiaServiceArea.VANHEMMUUDEN_VAHVISTAMINEN]: [
    AsiaType.TUNNUSTETTU_ENNEN_SYNTYMAA,
    AsiaType.TUNNUSTETTU_SYNTYMAN_JALKEEN,
    AsiaType.VANHEMMUUDEN_KUMOAMINEN,
  ],
  [AsiaServiceArea.ULKOMAALAISEN_REKISTEROINTI]: [
    AsiaType.KOTIKUNNAN_REKISTEROINTI,
    AsiaType.ENSIREKISTEROINTI,
    AsiaType.KANSALAISUUS_JA_ULKOMAATIEDOT,
  ],
  [AsiaServiceArea.EDUNVALVONTAVALTUUTUS_JA_EDUNVALVONNAN_ALKAMINEN]: [
    AsiaType.EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN,
    AsiaType.EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMINEN,
    AsiaType.SELVITYSTEHTAVA,
    AsiaType.EDUNVALVOJAN_MAARAAMINEN_ILMOITUKSELLA,
  ],
  [AsiaServiceArea.ELAMANTAPAHTUMIEN_REKISTEROINTI]: [
    AsiaType.SIVIILISAADYN_REKISTEROINTI_KOTIMAINEN,
    AsiaType.SIVIILISAADYN_REKISTEROINTI_ULKOMAINEN,
    AsiaType.KUOLEMAN_REKISTEROINTI_KOTIMAINEN,
    AsiaType.KUOLEMAN_REKISTEROINTI_ULKOMAINEN,
    AsiaType.LAPSEN_REKISTEROINTI_KOTIMAINEN,
    AsiaType.LAPSEN_REKISTEROINTI_ULKOMAINEN,
  ],
  [AsiaServiceArea.MUUTOT]: [AsiaType.KOTIMAAN_MUUTTO],
}

export const AsiaTypeServiceArea: Record<AsiaTypeId, AsiaServiceAreaId> = _.toPairs(ServiceAreaAsiaTypes).reduce(
  (acc, [serviceAreaId, asiaTypeIds]) => {
    asiaTypeIds.forEach((asiaTypeId) => {
      acc = {
        ...acc,
        [asiaTypeId]: serviceAreaId,
      }
    })
    return acc
  },
  {} as Record<AsiaTypeId, AsiaServiceAreaId>
)

export const asiaTypesToServiceAreas = (asiaTypeIds: AsiaTypeId[]): AsiaServiceAreaId[] => {
  return _.uniq(asiaTypeIds.map((asiaTypeId) => AsiaTypeServiceArea[asiaTypeId]))
}

export const getAsiaTypesInServiceAreas = (serviceAreaIds: AsiaServiceAreaId[]): AsiaTypeId[] =>
  _.chain(serviceAreaIds)
    .map((sa) => ServiceAreaAsiaTypes[sa])
    .flatten()
    .uniq()
    .value()

export type UiAsiaLinking = {
  originAsiaId: number
  targetAsiaId: number
  linkingTypeId: LinkingTypeId
  linkedAt: Date
}

export const AsiaStatus = keyMirror({
  ALOITTAMATTA: null,
  AUTOMAATTISESSA_PAATOKSENTEOSSA: null,
  KASITTELYSSA: null,
  VALMISTELTAVANA: null,
  RATKAISUEHDOTUS_ANNETTU: null,
  RATKAISTAVANA: null,
  PAATETTAVANA_KARAJAOIKEUDESSA: null,
  PAATOS_SAAPUNUT: null,
  ODOTTAA_MUUTTOPAIVAA: null,
  HARKINTA_AIKA: null,
  VALMIS: null,
})
export type AsiaStatusId = (typeof AsiaStatus)[keyof typeof AsiaStatus]
export const allAsiaStatusIds: AsiaStatusId[] = _.keys(AsiaStatus) as AsiaStatusId[]

export const AsiaHandlerRole = keyMirror({
  KASITTELIJA: null,
  ESITTELIJA: null,
  RATKAISIJA: null,
  KIRJAAJA: null,
  AUTOMAATTI: null,
})
export type AsiaHandlerRoleId = (typeof AsiaHandlerRole)[keyof typeof AsiaHandlerRole]
export const allAsiaHandlerRoleIds: AsiaHandlerRoleId[] = _.keys(AsiaHandlerRole) as AsiaHandlerRoleId[]

export const AsiaPersonSafetyProhibitedType = keyMirror({
  TURVAKIELTO: null,
  EI_TURVAKIELTOA: null,
  EI_TIETOA: null,
})
export type AsiaPersonSafetyProhibitedTypeId =
  (typeof AsiaPersonSafetyProhibitedType)[keyof typeof AsiaPersonSafetyProhibitedType]

export const AsiaPersonType = keyMirror({
  AMMATTIHENKILO: null,
  IHMINEN: null,
  ORGANISAATIO: null,
})
export type AsiaPersonTypeId = (typeof AsiaPersonType)[keyof typeof AsiaPersonType]
export const allAsiaPersonTypeIds = _.keys(AsiaPersonType) as AsiaPersonTypeId[]

export const AsiaPersonRole = keyMirror({
  ASIAKAS: null,
  HAKIJA: null,
  HUOLLETTAVA: null,
  SYNNYTTAJA: null,
  VANHEMMUUDEN_TUNNUSTAJA: null,
  LAPSI: null,
  OIKEUDENOMISTAJA: null,
  LASTENVALVOJA: null,
  AVIOMIES: null,
  MUU: null,
  EDUNVALVONTAAN_ESITETTY: null,
  ILMOITTAJA: null,
  EDUNVALVOJAKSI_EHDOTETTU: null,
  MUU_HENKILO: null,
  MUUTTAJA: null,
  KANSSAMUUTTAJA: null,
  PUOLISO_A: null,
  PUOLISO_B: null,
})
export type AsiaPersonRoleId = (typeof AsiaPersonRole)[keyof typeof AsiaPersonRole]
export const allPersonRoleIds = _.keys(AsiaPersonRole) as AsiaPersonRoleId[]

export const AsiaEventType = keyMirror({
  TULLUT_VIREILLE: null,
  OTETTU_KASITTELYYN: null,
  VAIHDETTU_KASITTELIJAA: null,
  MUUTETTU_ASIA: null,
  LISATTY_HUOMIO: null,
  POISTETTU_HUOMIO: null,
  MUUTETTU_HUOMIO: null,
  VALMIS: null,
  LISATTY_ASIAKIRJA: null,
  POISTETTU_ASIAKIRJA: null,
  MUUTETTU_ASIAKIRJA: null,
  LISATTY_APOSTILLE: null,
  MUUTETTU_APOSTILLE: null,
  OTETTU_VALMISTELTAVAKSI: null,
  OTETTU_RATKAISTAVAKSI: null,
  VAIHDETTU_ESITTELIJAA: null,
  VAIHDETTU_RATKAISIJAA: null,
  PALAUTETTU_VALMISTELTAVAKSI: null,
  LAHETETTY_RATKAISTAVAKSI: null,
  LISATTY_HENKILO: null,
  POISTETTU_HENKILO: null,
  MUUTETTU_HENKILO: null,
  LISATTY_TOIMENPIDE: null,
  MUUTETTU_TOIMENPIDE: null,
  SULJETTU_TOIMENPIDE: null,
  LAHETETTY_TOIMENPIDEPYYNTO: null,
  SAATU_VASTAUS_TOIMENPIDEPYYNTOON: null,
  LISATTY_RYHMAAN: null,
  POISTETTU_RYHMASTA: null,
  OTETTU_AUTOMAATTISEEN_PAATOKSENTEKOON: null,
  PAATETTAVANA_KARAJAOIKEUDESSA: null,
  PAATOS_SAAPUNUT: null,
  ODOTTAA_MUUTTOPAIVAA: null,
  LISATTY_ASIAKIRJATARKASTUS: null,
  MUUTETTU_ASIAKIRJATARKASTUS: null,
  HARKINTA_AIKA: null,
})
export type AsiaEventTypeId = (typeof AsiaEventType)[keyof typeof AsiaEventType]
export const allAsiaEventTypeIds = _.keys(AsiaEventType) as AsiaEventTypeId[]

export const AsiaEventTypeSourceTable: Record<AsiaEventTypeId, SourceTableId> = {
  TULLUT_VIREILLE: SourceTable.ASIA,
  OTETTU_KASITTELYYN: SourceTable.ASIA,
  VAIHDETTU_KASITTELIJAA: SourceTable.ASIA,
  MUUTETTU_ASIA: SourceTable.ASIA,
  LISATTY_HUOMIO: SourceTable.ASIAHUOMIO,
  POISTETTU_HUOMIO: SourceTable.ASIAHUOMIO,
  MUUTETTU_HUOMIO: SourceTable.ASIAHUOMIO,
  VALMIS: SourceTable.ASIA,
  LISATTY_ASIAKIRJA: SourceTable.ASIAKIRJA,
  POISTETTU_ASIAKIRJA: SourceTable.ASIAKIRJA,
  MUUTETTU_ASIAKIRJA: SourceTable.ASIAKIRJA,
  LISATTY_APOSTILLE: SourceTable.APOSTILLE,
  MUUTETTU_APOSTILLE: SourceTable.APOSTILLE,
  OTETTU_VALMISTELTAVAKSI: SourceTable.ASIA,
  OTETTU_RATKAISTAVAKSI: SourceTable.ASIA,
  VAIHDETTU_ESITTELIJAA: SourceTable.ASIA,
  VAIHDETTU_RATKAISIJAA: SourceTable.ASIA,
  PALAUTETTU_VALMISTELTAVAKSI: SourceTable.ASIA,
  LAHETETTY_RATKAISTAVAKSI: SourceTable.ASIA,
  LISATTY_HENKILO: SourceTable.ASIAHENKILO,
  POISTETTU_HENKILO: SourceTable.ASIAHENKILO,
  MUUTETTU_HENKILO: SourceTable.ASIAHENKILO,
  LISATTY_TOIMENPIDE: SourceTable.TOIMENPIDE,
  MUUTETTU_TOIMENPIDE: SourceTable.TOIMENPIDE,
  SULJETTU_TOIMENPIDE: SourceTable.TOIMENPIDE,
  LAHETETTY_TOIMENPIDEPYYNTO: SourceTable.TOIMENPIDE,
  SAATU_VASTAUS_TOIMENPIDEPYYNTOON: SourceTable.TOIMENPIDE,
  LISATTY_RYHMAAN: SourceTable.ASIARYHMA,
  POISTETTU_RYHMASTA: SourceTable.ASIARYHMA,
  OTETTU_AUTOMAATTISEEN_PAATOKSENTEKOON: SourceTable.ASIA,
  PAATETTAVANA_KARAJAOIKEUDESSA: SourceTable.ASIA,
  PAATOS_SAAPUNUT: SourceTable.ASIA,
  ODOTTAA_MUUTTOPAIVAA: SourceTable.ASIA,
  LISATTY_ASIAKIRJATARKASTUS: SourceTable.ASIAKIRJATARKASTUS,
  MUUTETTU_ASIAKIRJATARKASTUS: SourceTable.ASIAKIRJATARKASTUS,
  HARKINTA_AIKA: SourceTable.ASIA,
}

export const ExternalToimenpideEventType = _.pick(
  AsiaEventType,
  'LAHETETTY_TOIMENPIDEPYYNTO',
  'SAATU_VASTAUS_TOIMENPIDEPYYNTOON'
)
export type ExternalToimenpideEventTypeId =
  (typeof ExternalToimenpideEventType)[keyof typeof ExternalToimenpideEventType]

export const DiaariOrganization = {
  DVV: 'DVV',
}

export const DiaariTarget = {
  ASIA: 'A',
  APOSTILLE: 'APOSTILLE',
}

export const DiaariSource = keyMirror({
  ELSA: null,
  MAISTRAATTI_UI: null,
})
export type DiaariSourceId = (typeof DiaariSource)[keyof typeof DiaariSource]

export const ManualRatkaisuType = keyMirror({
  RATKAISEMATTA: null,
  RATKAISTU: null,
  REKISTERIIN_MERKINTA: null,
  OSITTAIN_KIELTEINEN: null,
  MYONTEINEN_PAATOS: null,
  KIELTEINEN_PAATOS: null,
  TUTKIMATTAJATTAMISPAATOS: null,
  RAUENNUT: null,
  TARKASTETTU: null,
  PALAUTTETTU_TARKASTAMATTOMANA: null,
  SIIRRETTY_LASTENVALVOJALLE: null,
  SILLENSA_JATETTY: null,
  AIHEETON_VIENTI: null,
  ASIAN_SIIRTO: null,
  YHDISTETTY_TOISEEN_ASIAAN: null,
  EI_TOIMENPITEITA: null,
  UUSI_ASIA_VIREILLE: null,
  LOPPULAUSUNTO: null,
  MUU_TOIMENPIDE: null,
  HYLATTY: null,
})
export type ManualRatkaisuTypeId = (typeof ManualRatkaisuType)[keyof typeof ManualRatkaisuType]

export const AutomaticRatkaisuType = keyMirror({
  AUTOMAATTISESTI_KASITELTY: null,
})
export type AutomaticRatkaisuTypeId = (typeof AutomaticRatkaisuType)[keyof typeof AutomaticRatkaisuType]

export const RatkaisuType = {
  ...ManualRatkaisuType,
  ...AutomaticRatkaisuType,
}
export type RatkaisuTypeId = (typeof RatkaisuType)[keyof typeof RatkaisuType]
export const allRatkaisuTypeIds = _.keys(RatkaisuType) as RatkaisuTypeId[]

export type RatkaisuMetadata = {
  defaultRatkaisuTypeId: RatkaisuTypeId
  manualRatkaisuTypeIds: ManualRatkaisuTypeId[]
  automaticRatkaisuTypeIds: AutomaticRatkaisuTypeId[]
}

export const LinkingType = keyMirror({
  JAKAMINEN: null,
  YHDISTAMINEN: null,
})
export type LinkingTypeId = (typeof LinkingType)[keyof typeof LinkingType]
export const allLinkingTypeIds = _.keys(LinkingType) as LinkingTypeId[]

export const GroupType = keyMirror({
  NIPPU: null,
  LUPANIPPU: null,
})
export type GroupTypeId = (typeof GroupType)[keyof typeof GroupType]
export const allGroupTypeIds = _.keys(GroupType) as GroupTypeId[]

export type AsiaGroupId = number
