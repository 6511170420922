import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
  Block,
  Button,
  InlineAlert,
  ModalTitle,
  Modal,
  ModalFooter,
  ModalContent,
  LoadingSpinner,
  IconArrowLeft,
  IconRemove,
  Paragraph,
} from 'suomifi-ui-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import {
  OmaisuusluetteloFormStates,
  PaatostiliFormStates,
  TilintarkastusRoutePath,
  currentNavigationSteps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkLahetaTiliTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  getTilintarkastusStore,
  saveDraft,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { submitTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/actions/submit-tilintarkastus-actions'
import {
  NavButtonContainer,
  NavButtonGroupContainer,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import {
  AsiointiFeatureFlag,
  isAsiointiUiFeatureEnabled,
} from 'holhous-common/src/vtj/asiointi-feature-flag'
import AbortDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/AbortDialog'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import SaveDialog from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/SaveDialog'
import { useValittuEvtvVaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { runInAction } from 'mobx'
import { LongIsoDateString } from 'edunvalvonta-asiointi/src/vtj/asiointi/evtv-asiointi/evtv-api.type'
import { resolveFormErrors } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-state.util'

export const LahetaTiliVahvistusModal: React.FC<{
  visible: boolean
  onSubmit: () => unknown
  onClose: () => unknown
}> = ({ visible, onSubmit, onClose }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 'm'
  const { asiaType } = getTili()

  return (
    <Modal
      visible={visible}
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      onEscKeyDown={onClose}
      scrollable={false}
      variant={isTablet ? 'default' : 'smallScreen'}
    >
      <ModalContent>
        <ModalTitle aria-live="polite" aria-atomic="true">
          {t(translations[asiaType].lahetaTili)}
        </ModalTitle>
        {asiaType === 'OMAISUUSLUETTELO' && (
          <Paragraph mb={verticalMarginToken}>
            {t('haluatkoLahettaaOmaisuusluettelonInfo')}
          </Paragraph>
        )}
        <Paragraph>{t(translations[asiaType].haluatkoLahettaaTilin)}</Paragraph>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={onSubmit}
          data-test-id={mkLahetaTiliTestId('modal-laheta-painike')}
        >
          {t('laheta')}
        </Button>
        <Button
          onClick={onClose}
          variant="secondary"
          data-test-id={mkLahetaTiliTestId('modal-peruuta-painike')}
        >
          {t('cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const LahetaTiliNotifications: React.FC = observer(() => {
  const store = getTilintarkastusStore()
  const showLoading = store.submitStatus === 'in-progress'
  const showError =
    store.submitStatus === 'none' && store.submitError !== undefined
  return (
    <>
      {showLoading && <LahetaTiliLoading />}
      {showError && <LahetaTiliError />}
    </>
  )
})

export const LahetaTiliLoading: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="neutral"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('tilia-lahetetaan')}
      mb={'s'}
    >
      <div ref={ref} tabIndex={-1}>
        <LoadingSpinner
          status={'loading'}
          text={t('tiliaLahetetaan')}
          textAlign="right"
          variant="small"
        />
      </div>
    </InlineAlert>
  )
}

const LahetaTiliError: React.FC = () => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.scrollIntoView()
  }, [])

  return (
    <InlineAlert
      status="error"
      smallScreen={!isTablet}
      data-test-id={mkLahetaTiliTestId('virhe-lahetyksessa')}
      tabIndex={0}
      mb={'s'}
    >
      <div ref={ref} tabIndex={-1}>
        {t('lahetysEpaonnistuiYritaUudelleen')}
      </div>
    </InlineAlert>
  )
}

export const FormSubmitBar: React.FC<{
  next: TilintarkastusRoutePath
  forms: Partial<PaatostiliFormStates | OmaisuusluetteloFormStates>
  onErrorRef: RefObject<HTMLDivElement>
}> = observer(({ forms, next, onErrorRef }) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const vaatimus = useValittuEvtvVaatimus()
  const [lahetaTiliVahvistusModalVisible, setLahetaTiliVahvistusModalVisible] =
    useState(false)
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const { handleSubmit } = useFormContext()
  const store = getTilintarkastusStore()
  const openFrontPage = () => navigate('/')
  const [isAbortDialogVisible, setAbortDialogVisible] = useState(false)

  const [isSaveDialogVisible, setSaveDialogVisible] = useState(false)
  const location = useLocation()

  const { asiaType } = getTili()
  const { excludeSteps } = getTilintarkastusStore()
  const { previous } = currentNavigationSteps(
    asiaType,
    excludeSteps,
    location.pathname as TilintarkastusRoutePath
  )

  const onAbort = () => {
    setAbortDialogVisible(false)
    openFrontPage()
  }

  const onSaveAndExit = () => {
    void saveDraft()
    setSaveDialogVisible(false)
    openFrontPage()
  }

  return (
    <section data-test-id={mkLahetaTiliTestId('container')}>
      <LahetaTiliNotifications />
      <LahetaTiliVahvistusModal
        onSubmit={async () => {
          if (store.submitStatus === 'none') {
            setLahetaTiliVahvistusModalVisible(false)
            const response = await submitTili()
            if (response.isOk) {
              runInAction(() => {
                vaatimus.sentDate =
                  new Date().toISOString() as LongIsoDateString
              })
              navigate(next)
            }
          }
        }}
        onClose={() => setLahetaTiliVahvistusModalVisible(false)}
        visible={lahetaTiliVahvistusModalVisible}
      />
      <SaveDialog
        visible={isSaveDialogVisible}
        onSave={onSaveAndExit}
        onClose={() => {
          setSaveDialogVisible(false)
        }}
      />
      <AbortDialog
        visible={isAbortDialogVisible}
        onAbort={onAbort}
        onClose={() => {
          setAbortDialogVisible(false)
        }}
      />
      <Block mt={verticalMarginToken}>
        <NavButtonContainer>
          {isAsiointiUiFeatureEnabled(AsiointiFeatureFlag.TALLENNUS) ? (
            <Button
              data-test-id={mkLahetaTiliTestId('save-button')}
              onClick={() => setSaveDialogVisible(true)}
              variant="secondary"
            >
              {t('tallennaJaPalaaEtusivulle')}
            </Button>
          ) : (
            <Button
              data-test-id={mkLahetaTiliTestId('abort-button')}
              variant="secondaryLight"
              icon={<IconRemove />}
              onClick={() => setAbortDialogVisible(true)}
            >
              {t('keskeyta')}
            </Button>
          )}

          <NavButtonGroupContainer>
            <Button
              variant="secondary"
              icon={<IconArrowLeft />}
              onClick={() => {
                void saveDraft()
                navigate(previous)
              }}
            >
              {t('previous')}
            </Button>
            <Block ml="s" />
            <Button
              data-test-id={mkLahetaTiliTestId('send-button')}
              onClick={async () => {
                await handleSubmit(() => {
                  void saveDraft()
                  const errors = resolveFormErrors(forms, {})
                  if (Object.values(errors).length === 0) {
                    setLahetaTiliVahvistusModalVisible(true)
                  } else {
                    setTimeout(() => onErrorRef?.current?.focus())
                  }
                })()
              }}
            >
              {t('laheta')}
            </Button>
          </NavButtonGroupContainer>
        </NavButtonContainer>
      </Block>
    </section>
  )
})

const translations = {
  OMAISUUSLUETTELO: {
    lahetaTili: 'lahetaOmaisuusluettelo',
    haluatkoLahettaaTilin: 'haluatkoLahettaaOmaisuusluettelon',
  },
  PAATOSTILI: {
    lahetaTili: 'lahetaPaatostili',
    haluatkoLahettaaTilin: 'haluatkoLahettaaPaatostilin',
  },
}
