import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Text } from 'suomifi-ui-components'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { mkAlaikainenAsuminenFormTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { action } from 'mobx'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { AsuinpaikkaForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/form/yleiskatsaus/AsuinpaikkaForm'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'

const AlaikainenAsuminenForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'xl' : 's'
  const {
    lomake: { tili },
  } = getTilintarkastusStore()
  return (
    <section data-test-id={mkAlaikainenAsuminenFormTestId('container')}>
      <Heading variant={'h3'}>{t('asuinpaikka')}</Heading>
      <Block mt={verticalMarginToken} />
      <Text>{t('alaikainenAsuinpaikkaPaaOhje')}</Text>
      <Block mt={verticalMarginToken} />

      <FormRadioButtonGroup
        required
        value={tili.asuuHuoltajanKanssa?.toString()}
        data-test-id={mkAlaikainenAsuminenFormTestId('radio')}
        labelText={t('alaikainenAsuinpaikkaOhje')}
        updateValue={action((value) => {
          tili.asuuHuoltajanKanssa = value === 'true'
        })}
        radioButtons={[
          { value: 'false', labelText: t('ei') },
          { value: 'true', labelText: t('kylla') },
        ]}
      />

      {tili.asuuHuoltajanKanssa ? (
        <Block mt={verticalMarginToken}>
          <FormTextArea
            data-test-id={mkAlaikainenAsuminenFormTestId('huoltajien-nimet')}
            value={tili.huoltajienNimet}
            updateValue={action((value) => {
              tili.huoltajienNimet = value
            })}
            required
            labelText={t('kenenKanssaHenkiloAsuu')}
            hintText={t('ilmoitaHenkiloidenNimet')}
            maxLength={1000}
            validate={commonXssValidator(t)}
          />
        </Block>
      ) : (
        <Block mt={verticalMarginToken}>
          <AsuinpaikkaForm />
        </Block>
      )}
    </section>
  )
})

export default AlaikainenAsuminenForm
